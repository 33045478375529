import { usePrivy } from '@privy-io/react-auth';
import { firestore } from './firebase';
import { doc, setDoc, getDoc, collection, query, where, getDocs, arrayUnion, arrayRemove, updateDoc } from 'firebase/firestore';
import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import debounce from 'lodash.debounce';

export const usePrivyAuth = () => {
  const { login: privyLogin, logout: privyLogout, authenticated, user, ready, getAccessToken, getEthereumProvider } = usePrivy();
  const queryClient = useQueryClient();
  const [hasSynced, setHasSynced] = useState(false);
  const hasSyncedRef = useRef(false);

  const login = async () => {
    if (!ready) {
      console.log('Privy is not ready yet.');
      return;
    }
    try {
      await privyLogin();
    } catch (error) {
      console.error('Error logging in with Privy', error);
    }
  };

  const logout = async () => {
    if (!ready) {
      console.log('Privy is not ready yet.');
      return;
    }
    try {
      await privyLogout();
      setHasSynced(false);
      hasSyncedRef.current = false;
      queryClient.clear();
    } catch (error) {
      console.error('Error logging out', error);
    }
  };

  const getEthereumAddressForPrivyId = async (privyId) => {
    const usersRef = collection(firestore, 'users');
    const q = query(usersRef, where('privyUserId', '==', privyId));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const userData = querySnapshot.docs[0].data();
      return userData.ethereumAddress;
    }
    return null;
  };

  const followUser = async (userToFollowId) => {
    if (!authenticated || !user) {
      console.log('User not authenticated');
      return;
    }

    const currentUserRef = doc(firestore, 'users', user.id);
    const userToFollowRef = doc(firestore, 'users', userToFollowId);

    try {
      await updateDoc(currentUserRef, {
        following: arrayUnion(userToFollowId)
      });

      await updateDoc(userToFollowRef, {
        followers: arrayUnion(user.id)
      });

      console.log('Successfully followed user');
    } catch (error) {
      console.error('Error following user:', error);
    }
  };

  const unfollowUser = async (userToUnfollowId) => {
    if (!authenticated || !user) {
      console.log('User not authenticated');
      return;
    }

    const currentUserRef = doc(firestore, 'users', user.id);
    const userToUnfollowRef = doc(firestore, 'users', userToUnfollowId);

    try {
      await updateDoc(currentUserRef, {
        following: arrayRemove(userToUnfollowId)
      });

      await updateDoc(userToUnfollowRef, {
        followers: arrayRemove(user.id)
      });

      console.log('Successfully unfollowed user');
    } catch (error) {
      console.error('Error unfollowing user:', error);
    }
  };

  const syncUserMutation = useMutation(async () => {
    if (!authenticated || !user || hasSyncedRef.current) {
      console.log('Skipping sync: Not authenticated or no user or already synced');
      return;
    }

    const accessToken = await getAccessToken();
    if (!accessToken) {
      throw new Error('Failed to get Privy access token');
    }

    let ethereumAddress = null;
    try {
      const provider = await getEthereumProvider();
      const accounts = await provider.request({ method: 'eth_accounts' });
      ethereumAddress = accounts[0];
    } catch (error) {
      console.error('Error getting Ethereum address:', error);
    }

    const userDocRef = doc(firestore, 'users', user.id);
    const existingUserDoc = await getDoc(userDocRef);
    const existingData = existingUserDoc.exists() ? existingUserDoc.data() : {};

    const userDoc = {
      ...existingData,
      privyUserId: user.id,
      email: user.email || 'N/A',
      twitterHandle: user.twitter?.username || 'Anonymous',
      photoURL: user.twitter?.profilePictureUrl?.replace('_normal', '') || 'default-photo-url',
      lastLogin: new Date(),
      ethereumAddress: ethereumAddress || null,
      following: existingData.following || [],
      followers: existingData.followers || [],
    };

    await setDoc(userDocRef, userDoc, { merge: true });

    return { ...userDoc };
  }, {
    onSuccess: (data) => {
      queryClient.setQueryData(['user', user?.id], data);
      setHasSynced(true);
      hasSyncedRef.current = true;
      console.log('User synced with Firebase successfully');
    },
    onError: (error) => {
      console.error('Error syncing user with Firebase', error);
    },
  });

  const debouncedSyncUser = useMemo(
    () => debounce(() => {
      if (!hasSyncedRef.current) {
        console.log('Debounced syncUserMutation called');
        syncUserMutation.mutate();
      } else {
        console.log('Debounced syncUserMutation skipped: Already synced');
      }
    }, 60000),
    [syncUserMutation]
  );

  useEffect(() => {
    if (authenticated && user && !hasSyncedRef.current) {
      debouncedSyncUser();
    }
  }, [authenticated, user, debouncedSyncUser]);

  useEffect(() => {
    if (syncUserMutation.isSuccess) {
      hasSyncedRef.current = true;
    }
  }, [syncUserMutation.isSuccess]);

  const subscribeToPushNotifications = useCallback(async () => {
    if (!user || !authenticated) {
      console.log('User not authenticated');
      return;
    }

    if ('serviceWorker' in navigator && 'PushManager' in window) {
      try {
        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: process.env.REACT_APP_VAPID_PUBLIC_KEY
        });

        const userDocRef = doc(firestore, 'users', user.id);
        await updateDoc(userDocRef, {
          pushSubscription: subscription.toJSON()
        });

        console.log('Push notification subscription successful');
      } catch (error) {
        console.error('Error subscribing to push notifications:', error);
      }
    }
  }, [user, authenticated]);

  return {
    login,
    logout,
    authenticated,
    ready,
    user,
    syncUserWithFirebase: syncUserMutation.mutateAsync,
    isSyncing: syncUserMutation.isLoading,
    getAccessToken,
    getEthereumProvider,
    followUser,
    unfollowUser,
    subscribeToPushNotifications,
  };
};