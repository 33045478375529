export const FANTOM_TESTNET = {
  id: 1946,
  name: 'Minato',
  network: 'fantom-testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Minato',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.minato.soneium.org'],
    },
  },
  blockExplorers: {
    default: { name: 'FTMScan', url: 'https://explorer-testnet.soneium.org' },
  },
};

export const CURRENT_NETWORK = FANTOM_TESTNET;

export const CHAIN_ID = CURRENT_NETWORK.id;
export const RPC_URL = CURRENT_NETWORK.rpcUrls.default.http[0];
export const BLOCK_EXPLORER_URL = CURRENT_NETWORK.blockExplorers.default.url;