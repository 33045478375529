import React from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, Button } from '@mui/material';
import { Home, Search, NotificationsNone, Person, People } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import FrostedGlass from './FrostedGlass';

const Sidebar = ({ user, onNewPost, open, onToggle }) => {
  const location = useLocation();

  const menuItems = [
    { icon: <Home />, text: 'Home', path: '/' },
    { icon: <People />, text: 'Following', path: '/following' },
    { icon: <Search />, text: 'Search', path: '/search' },
    { icon: <NotificationsNone />, text: 'Notifications', path: '/notifications' },
    { icon: <Person />, text: 'Profile', path: '/profile' },
  ];

  return (
    <FrostedGlass
      darkMode
      intensity="medium"
      sx={{
        width: open ? 275 : 70,
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        p: 2,
        overflowY: 'auto',
        transition: 'width 0.3s ease',
        zIndex: 1200,
      }}
    >
      <Box sx={{ mb: 2 }}>
        {/* Insert your custom logo here */}
      </Box>
      <List sx={{ mb: 2 }}>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.text}
            component={Link}
            to={item.path}
            selected={location.pathname === item.path}
            sx={{
              borderRadius: '9999px',
              mb: 1,
              justifyContent: open ? 'flex-start' : 'center',
              '&.Mui-selected': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: open ? 40 : 'auto' }}>
              {item.icon}
            </ListItemIcon>
            {open && <ListItemText primary={item.text} />}
          </ListItem>
        ))}
      </List>
      <Button
        variant="contained"
        fullWidth
        onClick={onNewPost}
        sx={{
          borderRadius: '9999px',
          py: 1.5,
          textTransform: 'none',
          fontWeight: 'bold',
          fontSize: '1.1rem',
        }}
      >
        {open ? 'Post' : '+'}
      </Button>
    </FrostedGlass>
  );
};

export default Sidebar;