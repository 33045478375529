import React, { useState, useEffect } from 'react';
import { BottomNavigation, BottomNavigationAction, Badge, Box, useMediaQuery, useTheme } from '@mui/material';
import { Home, Search, People, Notifications, TrendingUp } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { usePrivyAuth } from '../firebase/auth';

const API_URL = process.env.REACT_APP_SERVER_URL || 'https://inari-server-63b59ac586b0.herokuapp.com';

const BottomNav = () => {
  const location = useLocation();
  const { user } = usePrivyAuth();
  const [opacity, setOpacity] = useState(1);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      const scrollPercentage = scrollPosition / (documentHeight - windowHeight);
      const newOpacity = Math.max(1 - scrollPercentage * 0.5, 0);

      setOpacity(newOpacity);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (user && user.id) {
      // Set up SSE connection
      const eventSource = new EventSource(`${API_URL}/api/notifications/sse/${user.id}`);

      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === 'new_notification') {
          setHasNewNotifications(true);
        }
      };

      eventSource.onerror = (error) => {
        console.error('SSE Error:', error);
        eventSource.close();
      };

      return () => {
        eventSource.close();
      };
    }
  }, [user]);

  const handleNotificationClick = () => {
    setHasNewNotifications(false);
  };

  const opacityToHex = (opacity) => {
    const alpha = Math.round(opacity * 255).toString(16);
    return alpha.padStart(2, '0');
  };

  if (isDesktop) {
    return null; // Don't render the BottomNav on desktop
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        borderTop: '1px solid rgba(255, 255, 255, 0.12)',
      }}
    >
      <BottomNavigation
        value={location.pathname}
        showLabels
        sx={{
          height: 56,
          backgroundColor: `#181825${opacityToHex(opacity)}`,
          transition: 'background-color 0.3s ease-in-out',
          '& .MuiBottomNavigationAction-root': {
            color: '#cdd6f4',
            minWidth: 'auto',
            padding: '6px 0',
          },
          '& .Mui-selected': {
            color: `rgba(255, 255, 255, ${Math.min(opacity + 0.3, 1)})`,
          },
        }}
      >
        <BottomNavigationAction 
          label="Home" 
          icon={<Home sx={{ fontSize: 20 }} />}
          component={Link} 
          to="/" 
          value="/" 
        />
        <BottomNavigationAction 
          label="Following" 
          icon={<People sx={{ fontSize: 20 }} />}
          component={Link} 
          to="/following" 
          value="/following" 
        />
        <BottomNavigationAction 
          label="Search" 
          icon={<Search sx={{ fontSize: 20 }} />}
          component={Link} 
          to="/search" 
          value="/search" 
        />
        <BottomNavigationAction 
          label="Notifications" 
          icon={
            <Badge 
              color="error" 
              variant="dot" 
              invisible={!hasNewNotifications}
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: '#f38ba8',
                }
              }}
            >
              <Notifications sx={{ fontSize: 20 }} />
            </Badge>
          }
          component={Link} 
          to="/notifications" 
          value="/notifications" 
          onClick={handleNotificationClick}
        />
      </BottomNavigation>
      <Box sx={{ height: 'env(safe-area-inset-bottom)', backgroundColor: '#181825' }} />
    </Box>
  );
};

export default BottomNav;