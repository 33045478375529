import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { styled } from '@mui/system';

const FrostedGlassBox = styled(Box)(({ theme }) => ({
  background: 'rgba(30, 30, 46, 0.9)',
  backdropFilter: 'blur(10px)',
  borderRadius: '12px',
  border: '1px solid rgba(180, 190, 254, 0.2)',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease-in-out',
  padding: '16px',
  width: '100%',
  maxWidth: '600px',
  '&:hover': {
    boxShadow: '0 6px 40px rgba(0, 0, 0, 0.2)',
  },
}));

const SearchPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    if (!searchTerm.trim()) return;
    const cleanedSearchTerm = searchTerm.trim().replace('@', '');
    navigate(`/${cleanedSearchTerm}`);
  };

  return (
    <Box sx={{ 
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      padding: '0 16px',
      // Ensure the content doesn't overlap with the app bar or bottom navigation
      pt: 'calc(56px + env(safe-area-inset-top))',
      pb: 'calc(56px + env(safe-area-inset-bottom))',
    }}>
      <FrostedGlassBox>
        <form onSubmit={handleSearch}>
          <Box sx={{ display: 'flex' }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="username"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ 
                mr: 1,
                '& .MuiOutlinedInput-root': {
                  color: '#cdd6f4',
                  '& fieldset': {
                    borderColor: 'rgba(180, 190, 254, 0.2)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(180, 190, 254, 0.4)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#f9e2af',
                  },
                },
                '& .MuiInputBase-input::placeholder': {
                  color: '#7f849c',
                  opacity: 1,
                },
              }}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: '#f9e2af',
                color: '#1e1e2e',
                '&:hover': {
                  backgroundColor: '#f5c2e7',
                },
              }}
            >
              <SearchIcon />
            </Button>
          </Box>
        </form>
      </FrostedGlassBox>
    </Box>
  );
};

export default SearchPage;