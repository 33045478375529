// src/firebase/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, serverTimestamp } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyCBX2Fg2eF6VkluuPNWgm7TKiZWrCDqyJo",
    authDomain: "banger-be9fe.firebaseapp.com",
    projectId: "banger-be9fe",
    storageBucket: "banger-be9fe.appspot.com",
    messagingSenderId: "441168786464",
    appId: "1:441168786464:web:8e6f1cc18c9af274877845",
    measurementId: "G-EZQMHRJ3ZH"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

export { auth, firestore, serverTimestamp, storage };
export default app;