import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Box, Typography, Button, SvgIcon } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import CommentIcon from '@mui/icons-material/Comment';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { FaToriiGate } from "react-icons/fa";

// Custom Ethereum Icon (diamond only)
const EthereumIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 320 512">
    <path d="M311.9 260.8L160 353.6 8 260.8 160 0l151.9 260.8zM160 383.4L8 290.6 160 512l152-221.4-152 92.8z" />
  </SvgIcon>
);

const FeeScenario = ({ title, description, distribution }) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setAnimate(true), 500);
    return () => clearTimeout(timer);
  }, []);

  const ethVariants = {
    initial: { scale: 0, opacity: 0 },
    animate: { scale: 1, opacity: 1, transition: { duration: 0.5 } },
  };

  const arrowVariants = {
    initial: { width: 0, opacity: 0 },
    animate: { width: 'auto', opacity: 1, transition: { duration: 0.5, delay: 0.5 } },
  };

  return (
    <Box sx={{ mb: 4, p: 2, border: '1px solid black', borderRadius: 2 }}>
      <Typography variant="h6" gutterBottom>{title}</Typography>
      <Typography variant="body2" gutterBottom>{description}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
        <motion.div initial="initial" animate={animate ? "animate" : "initial"} variants={ethVariants}>
          <EthereumIcon sx={{ fontSize: 40 }} />
        </motion.div>
        <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%', mt: 2 }}>
          {distribution.map((item, index) => (
            <Box key={index} sx={{ textAlign: 'center' }}>
              <motion.div
                initial="initial"
                animate={animate ? "animate" : "initial"}
                variants={arrowVariants}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}
              >
                <ArrowDownwardIcon sx={{ fontSize: 30 }} />
              </motion.div>
              <motion.div
                initial={{ scale: 0, opacity: 0 }}
                animate={animate ? { scale: 1, opacity: 1 } : { scale: 0, opacity: 0 }}
                transition={{ delay: 1 + index * 0.2, duration: 0.5 }}
              >
                {item.icon}
                <Typography variant="caption" display="block">{item.label}</Typography>
                <Typography variant="body2">{item.percentage}%</Typography>
              </motion.div>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const AnimatedFeeFlowChart = () => {
  const [currentScenario, setCurrentScenario] = useState(0);
  const scenarios = [
    {
      title: "Regular Posts",
      description: "The entire fee goes to Torii.",
      distribution: [
        { icon: <FaToriiGate style={{ fontSize: 30 }} />, label: "Torii", percentage: 100 }
      ]
    },
    {
      title: "Comments",
      description: "The fee is split between the poster and Torii.",
      distribution: [
        { icon: <PersonIcon sx={{ fontSize: 30 }} />, label: "Poster", percentage: 50 },
        { icon: <FaToriiGate style={{ fontSize: 30 }} />, label: "Torii", percentage: 50 }
      ]
    },
    {
      title: "Replies to Comments",
      description: "The fee is split between the commenter and the post author.",
      distribution: [
        { icon: <CommentIcon sx={{ fontSize: 30 }} />, label: "Commenter", percentage: 50 },
        { icon: <PersonIcon sx={{ fontSize: 30 }} />, label: "Post Author", percentage: 50 }
      ]
    },
    {
      title: "Quote Tweets",
      description: "The fee is divided between the original poster and Torii.",
      distribution: [
        { icon: <PersonIcon sx={{ fontSize: 30 }} />, label: "Poster", percentage: 50 },
        { icon: <FaToriiGate style={{ fontSize: 30 }} />, label: "Torii", percentage: 50 }
      ]
    },
    {
      title: "Boosted Comments",
      description: "The fee is split between the poster and Torii. Minimum 10 USD required.",
      distribution: [
        { icon: <PersonIcon sx={{ fontSize: 30 }} />, label: "Poster", percentage: 50 },
        { icon: <FaToriiGate style={{ fontSize: 30 }} />, label: "Torii", percentage: 50 }
      ]
    }
  ];

  const nextScenario = () => {
    setCurrentScenario((prev) => (prev + 1) % scenarios.length);
  };

  return (
    <Box sx={{ p: 4 }}>
      <FeeScenario key={currentScenario} {...scenarios[currentScenario]} />
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Button
          variant="outlined"
          onClick={nextScenario}
          sx={{
            color: 'black',
            borderColor: 'black',
            '&:hover': {
              borderColor: '#FF4B3E',
              color: 'black'
            }
          }}
        >
          Next Scenario
        </Button>
      </Box>
      <Box sx={{ mt: 4, p: 2, border: '1px solid black', borderRadius: 2 }}>
        <Typography variant="body2">
          Note: Interacting with your own content is free.
        </Typography>
      </Box>
    </Box>
  );
};

export default AnimatedFeeFlowChart;
