import React, { useEffect, useState, useRef } from 'react';
import { useWallets } from '@privy-io/react-auth';
import { providers, utils } from 'ethers';
import { Box, Typography, Button, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { motion } from 'framer-motion';

const WalletInfo = () => {
  const { ready, wallets } = useWallets();
  const [balance, setBalance] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const providerRef = useRef(null);
  const walletRef = useRef(null);
  const intervalRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const setupWalletAndPolling = async () => {
      if (ready && wallets.length > 0) {
        const wallet = wallets.find(w => w.walletClientType === 'privy');
        if (wallet) {
          walletRef.current = wallet;
          providerRef.current = new providers.JsonRpcProvider("https://rpc.minato.soneium.org");

          try {
            const fetchAndUpdateBalance = async () => {
              const newBalance = await providerRef.current.getBalance(wallet.address);
              const newBalanceEther = parseFloat(utils.formatEther(newBalance)).toFixed(4);

              setBalance(prevBalance => {
                if (newBalanceEther !== prevBalance) {
                  setIsAnimating(true);
                  setTimeout(() => setIsAnimating(false), 2000);
                }
                return newBalanceEther;
              });
            };

            await fetchAndUpdateBalance();
            intervalRef.current = setInterval(fetchAndUpdateBalance, 5000);
          } catch (error) {
            console.error("Error setting up wallet and polling:", error);
          }
        }
      }
    };

    setupWalletAndPolling();

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [ready, wallets]);

  const shortenAddress = (address) => {
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  if (!ready || wallets.length === 0) return null;

  const wallet = wallets.find(w => w.walletClientType === 'privy');
  if (!wallet) return null;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
      <motion.div
        animate={{ 
          color: isAnimating ? '#89EA81' : '#a6e3a1',
          scale: isAnimating ? 1.1 : 1
        }}
        transition={{ duration: 0.5 }}
      >
        <Typography variant="body2" sx={{ mr: 1, fontWeight: 'bold', whiteSpace: 'nowrap' }}>
          {balance} ETH
        </Typography>
      </motion.div>
      <Tooltip title="Copy address">
        <Button
          variant="outlined"
          size="small"
          onClick={() => copyToClipboard(wallet.address)}
          endIcon={!isMobile && <ContentCopyIcon />}
          sx={{ 
            textTransform: 'none', 
            borderRadius: '16px',
            padding: '2px 8px',
            minWidth: '0',
            '& .MuiButton-endIcon': { marginLeft: '4px' },
            whiteSpace: 'nowrap',
          }}
        >
          {shortenAddress(wallet.address)}
        </Button>
      </Tooltip>
    </Box>
  );
};

export default WalletInfo;