import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline, Box, CircularProgress, Snackbar, Fab, useMediaQuery } from '@mui/material';
import { Add as AddIcon, Close as CloseIcon, ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import { useSwipeable } from 'react-swipeable';
import { usePrivy, useWallets } from '@privy-io/react-auth';
import { ethers } from 'ethers';
import Feed from './components/Feed';
import FollowingFeed from './components/FollowingFeed';
import NewPost from './components/NewPost';
import Login from './components/Login';
import Profile from './components/Profile';
import BottomNav from './components/BottomNav';
import CustomAppBar from './components/CustomAppBar';
import PostView from './components/PostView';
import SearchPage from './components/SearchPage';
import Notifications from './components/Notifications';
import PushNotificationSubscriber from './components/PushNotificationSubscriber';
import Sidebar from './components/Sidebar';
import TradingPage from './components/TradingPage';
import FrostedGlass from './components/FrostedGlass';
import { Dialog, DialogContent, Typography, IconButton } from '@mui/material';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#181825',
      paper: '#181825',
    },
    primary: {
      main: '#b4befe',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#181825',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#b4befe',
        },
      },
    },
  },
});

const CenteredLoading = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="100vh"
  >
    <CircularProgress />
  </Box>
);

const Layout = ({ children, showAddButton = true, user, showBottomNav, showAppBar = true }) => {
  const navigate = useNavigate();
  const [showNewPost, setShowNewPost] = useState(false);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const handleNewPost = () => {
    setShowNewPost(true);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      {isDesktop && (
        <Sidebar 
          user={user} 
          onNewPost={handleNewPost} 
          open={sidebarOpen} 
          onToggle={toggleSidebar}
        />
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          ml: isDesktop ? (sidebarOpen ? '275px' : '70px') : 0,
          width: isDesktop ? (sidebarOpen ? 'calc(100% - 275px)' : 'calc(100% - 70px)') : '100%',
          transition: 'margin-left 0.3s ease, width 0.3s ease',
        }}
      >
        {showAppBar && <CustomAppBar user={user} onMenuClick={toggleSidebar} />}
        <Box sx={{ 
          flex: 1, 
          overflow: 'auto', 
          pt: showAppBar ? 'calc(env(safe-area-inset-top) + 56px)' : 0, 
          pb: showBottomNav ? 'calc(env(safe-area-inset-bottom) + 56px)' : 0,
          bgcolor: '#181825',
        }}>
          <Box sx={{ 
            maxWidth: '600px', 
            width: '100%', 
            margin: '0 auto', 
            p: 2, 
          }}>
            {children}
          </Box>
        </Box>
        {showBottomNav && !isDesktop && <BottomNav />}
      </Box>
      {showAddButton && !isDesktop && (
        <Fab
          color="primary"
          aria-label="add"
          sx={{ position: 'fixed', bottom: 'calc(env(safe-area-inset-bottom) + 70px)', right: 16 }}
          onClick={handleNewPost}
        >
          <AddIcon />
        </Fab>
      )}
      {showNewPost && (
        <NewPost user={user} onClose={() => setShowNewPost(false)} />
      )}
    </Box>
  );
};

const Home = () => {
  const { authenticated, ready, user } = usePrivy();
  const [error, setError] = useState(null);
  
  if (!ready) return <CenteredLoading />;
  if (!authenticated) return <Navigate to="/login" />;

  return (
    <>
      <Feed user={user} />
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
    </>
  );
};

const PrivateRoute = ({ children, showAddButton = true, showAppBar = true }) => {
  const { authenticated, ready, user } = usePrivy();
  const [showBottomNav, setShowBottomNav] = useState(true);
  
  if (!ready) return <CenteredLoading />;
  if (!authenticated) return <Navigate to="/login" />;
  
  return (
    <Layout showAddButton={showAddButton} user={user} showBottomNav={showBottomNav} showAppBar={showAppBar}>
      {React.cloneElement(children, { setShowBottomNav })}
    </Layout>
  );
};

const App = ({ initialState }) => {
  const { ready, user } = usePrivy();
  const { wallets } = useWallets();
  const [appState, setAppState] = useState(initialState || {});
  const [showFundingDialog, setShowFundingDialog] = useState(false);
  const [userAddress, setUserAddress] = useState('');
  const [showCopiedSnackbar, setShowCopiedSnackbar] = useState(false);

  useEffect(() => {
    if (ready && user) {
      setAppState(prevState => ({ ...prevState, user }));
    }
  }, [ready, user]);

  useEffect(() => {
    const checkBalance = async () => {
      if (ready && user && wallets.length > 0) {
        const wallet = wallets.find(w => w.walletClientType === 'privy');
        if (wallet) {
          setUserAddress(wallet.address);
          const provider = new ethers.providers.JsonRpcProvider("https://rpc.minato.soneium.org");
          const balance = await provider.getBalance(wallet.address);
          if (balance.isZero()) {
            setShowFundingDialog(true);
          }
        }
      }
    };

    checkBalance();
  }, [ready, user, wallets]);

  useEffect(() => {
    // Force layout recalculation after component mounts
    window.dispatchEvent(new Event('resize'));
  }, []);

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(userAddress);
    setShowCopiedSnackbar(true);
  };

  if (!ready) {
    return <CenteredLoading />;
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <PushNotificationSubscriber />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
        <Route path="/following" element={<PrivateRoute><FollowingFeed /></PrivateRoute>} />
        <Route path="/new-post" element={<PrivateRoute showAddButton={false} showAppBar={false}><NewPost user={user} /></PrivateRoute>} />
        <Route path="/profile" element={<PrivateRoute><Profile userId={user?.id} /></PrivateRoute>} />
        <Route path="/:twitterHandle" element={<PrivateRoute><Profile /></PrivateRoute>} />
        <Route path="/post/:postId" element={<PrivateRoute showAddButton={false}><PostView /></PrivateRoute>} />
        <Route path="/post/:postId/comment/:commentId" element={<PrivateRoute showAddButton={false} showAppBar={false}><PostView /></PrivateRoute>} />
        <Route path="/search" element={<PrivateRoute><SearchPage /></PrivateRoute>} />
        <Route path="/notifications" element={<PrivateRoute><Notifications /></PrivateRoute>} />
      </Routes>
      <Dialog 
  open={showFundingDialog} 
  onClose={() => setShowFundingDialog(false)}
  PaperComponent={FrostedGlass}
  PaperProps={{
    intensity: 'medium',
    darkMode: true,
    style: { 
      borderRadius: '16px', 
      overflow: 'hidden',
      maxWidth: '500px',
      width: '90%'
    }
  }}
>
  <IconButton
    aria-label="close"
    onClick={() => setShowFundingDialog(false)}
    sx={{
      position: 'absolute',
      right: 8,
      top: 8,
      color: 'grey.500',
    }}
  >
    <CloseIcon />
  </IconButton>
  <DialogContent sx={{ textAlign: 'center', py: 4 }}>
    <Typography variant="h3" sx={{ mb: 2, color: '#cba6f7' }}>
      ⛩️
    </Typography>
    <Typography variant="h6" sx={{ mb: 2, color: '#cba6f7', fontWeight: 'bold' }}>
      Offering Required
    </Typography>
    <Typography variant="body1" sx={{ mb: 3, color: '#cdd6f4' }}>
      Please deposit some ETH on <span style={{ color: 'rgb(137, 180, 250)', fontWeight: 'bold' }}>Minato</span> to use inari
    </Typography>
    <Box 
      onClick={handleCopyAddress}
      sx={{ 
        backgroundColor: 'rgba(49, 50, 68, 0.6)',
        borderRadius: '8px',
        p: 2,
        mb: 2,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
          backgroundColor: 'rgba(49, 50, 68, 0.8)',
        }
      }}
    >
      <Typography variant="body2" sx={{ color: '#f9e2af', mr: 1, wordBreak: 'break-all', textAlign: 'left' }}>
        {userAddress}
      </Typography>
      <ContentCopyIcon sx={{ color: '#f9e2af', fontSize: '1rem', flexShrink: 0 }} />
    </Box>
  </DialogContent>
</Dialog>
<Snackbar
  open={showCopiedSnackbar}
  autoHideDuration={3000}
  onClose={() => setShowCopiedSnackbar(false)}
  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
  message={
    <span style={{ width: '100%', textAlign: 'center' }}>
      Address copied to clipboard
    </span>
  }
  ContentProps={{
    sx: {
      justifyContent: 'center',
    }
  }}
/>
    </ThemeProvider>
  );
};

export default App;