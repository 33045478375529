import React, { useState, useEffect, useRef } from 'react';
import { Button, Box, Typography, Container, useMediaQuery, useTheme, Accordion, AccordionSummary, AccordionDetails, Modal } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import { usePrivyAuth } from '../firebase/auth';
import AnimatedFeeFlowChart from './AnimatedFeeFlowChart';
import { useSwipeable } from 'react-swipeable';

const Login = () => {
  const { login, ready, authenticated } = usePrivyAuth();
  const navigate = useNavigate();
  const [currentSection, setCurrentSection] = useState(0);
  const sectionRefs = useRef([]);
  const containerRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (authenticated) {
      navigate('/');
    }
  }, [authenticated, navigate]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = sectionRefs.current.findIndex((ref) => ref === entry.target);
            setCurrentSection(index);
          }
        });
      },
      { threshold: 0.5 }
    );

    sectionRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      sectionRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  const scrollToSection = (index) => {
    if (index >= 0 && index < sections.length) {
      sectionRefs.current[index]?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleArrowClick = () => {
    scrollToSection(1); // Scroll to the second section (index 1)
  };

  const handleLogin = () => {
    if (isMobile) {
      login();
    } else {
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    login(); // Call login function when modal is closed
  };

  const handleJoinWaitlist = () => {
    window.open('https://forms.gle/1yhyZyYxAmFDH52C8', '_blank');
  };

  const sections = [
    {
      content: (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'space-between',
            py: isMobile ? 6 : 8,
          }}
        >
          <br/>
          <br/>
          <br/>
          <Typography
            variant={isMobile ? "h4" : "h3"}
            sx={{
              fontFamily: "'Noto Sans JP', sans-serif",
              color: "#b4befe",
              mb: isMobile ? 4 : 6,
              textAlign: 'center',
            }}
          >
            welcome to inari
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
            }}
          >
            <Box
              component="img"
              sx={{
                width: '60%',
                maxWidth: 250,
                mb: isMobile ? 4 : 6,
              }}
              alt="Kitsune Gate Logo"
              src={`${process.env.PUBLIC_URL}/maink.svg`}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleLogin}
              disabled={!ready || authenticated}
              sx={{
                bgcolor: '#f38ba8',
                '&:hover': { bgcolor: '#eba0ac' },
                mt: isMobile ? 2 : 4,
                mb: 2,
              }}
            >
              Enter
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleJoinWaitlist}
              sx={{
                borderColor: '#f38ba8',
                color: '#f38ba8',
                '&:hover': { borderColor: '#eba0ac', color: '#eba0ac' },
                mb: 4,
              }}
            >
              Join Waitlist
            </Button>
            <br/>
            <br/>
            <Box sx={{ textAlign: 'center', cursor: 'pointer' }} onClick={handleArrowClick}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                learn more
              </Typography>
              <KeyboardArrowDownIcon 
                sx={{ 
                  fontSize: 40,
                  animation: 'bounce 2s infinite',
                  '@keyframes bounce': {
                    '0%, 20%, 50%, 80%, 100%': {
                      transform: 'translateY(0)',
                    },
                    '40%': {
                      transform: 'translateY(-20px)',
                    },
                    '60%': {
                      transform: 'translateY(-10px)',
                    },
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      )
    },
    {
      title: "Enshrined Tickers",
      titleColor: "#a6e3a1", 
      content: "Ever bought the wrong token because the ticker is the same as the real one? inari introduces the concept of enshrined tickers; each time someone creates an ticker, it is sealed (or enshrined) into inari as unique. No two tickers can be the same."
    },
    {
      title: "Trade Tickers Not Tokens",
      titleColor: "#FAB387", 
      content: "Pick a ticker that has not been taken then mint it. Once a ticker has been created, users can buy and sell it on a bonding curve."
    },
    {
      title: "Liquid Conversations",
      titleColor: "#89b4fa",
      content: "Monetize every interaction with your posts. Whether it's a direct reply or users engaging in your comment section, earn revenue each time someone interacts with your content."
    },
    {
      title: "Earn Fees",
      titleColor: "#cba6f7", 
      content: "Every time someone comments on your post, they have to pay you a small fee of 1 USD. If users are replying to each other in your comments, you get 50% of the fee while the user being replied to gets 50%. This works the same if you are making an original post or quote tweeting another post. You also earn fees whenever someone buys are sells a ticker you created."
    },
  ];
      
  useEffect(() => {
    const handleWheel = (e) => {
      const delta = e.deltaY;
      if (delta > 0 && currentSection < sections.length - 1) {
        scrollToSection(currentSection + 1);
      } else if (delta < 0 && currentSection > 0) {
        scrollToSection(currentSection - 1);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('wheel', handleWheel);

      return () => {
        container.removeEventListener('wheel', handleWheel);
      };
    }
  }, [currentSection, sections.length]);

  const swipeHandlers = useSwipeable({
    onSwipedUp: (eventData) => {
      if (currentSection < sections.length - 1) {
        scrollToSection(currentSection + 1);
      }
    },
    onSwipedDown: (eventData) => {
      if (currentSection > 0) {
        scrollToSection(currentSection - 1);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <Box
      ref={containerRef}
      {...swipeHandlers}
      sx={{
        bgcolor: '#181825',
        height: '100vh',
        color: '#f5e0dc',
        overflowY: 'scroll',
        scrollSnapType: 'y mandatory',
        WebkitOverflowScrolling: 'touch',
      }}
    >
      <Container maxWidth="sm">
        {sections.map((section, index) => (
          <Box
            key={index}
            ref={(el) => (sectionRefs.current[index] = el)}
            sx={{
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              px: isMobile ? 2 : 4,
              scrollSnapAlign: 'start',
            }}
          >
            <Typography
              variant={isMobile ? "h5" : "h4"}
              gutterBottom
              sx={{
                fontFamily: "'Noto Sans JP', sans-serif",
                color: section.titleColor,
                mb: isMobile ? 2 : 3,
              }}
            >
              {section.title}
            </Typography>
            {typeof section.content === 'string' ? (
              <Typography 
                variant="body1" 
                sx={{ 
                  maxWidth: '100%',
                  fontSize: isMobile ? '1rem' : '1.1rem',
                  lineHeight: 1.6,
                }}
              >
                {section.content}
              </Typography>
            ) : (
              section.content
            )}
          </Box>
        ))}
      </Container>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: '#1e1e2e',
          border: '2px solid #cba6f7',
          boxShadow: 24,
          p: 4,
          color: '#f5e0dc',
          textAlign: 'center',
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
            Invite Only
          </Typography>
          <Typography id="modal-modal-description" sx={{ mb: 3 }}>
          We are currently in closed beta and are periodically granting access to users on our waitlist. Please join the waitlist to be among the first to experience our platform. If you have already received access, please proceed to log in.          </Typography>
         <Button onClick={handleCloseModal} sx={{ bgcolor: '#f38ba8', color: '#1e1e2e', '&:hover': { bgcolor: '#eba0ac' } }}>
            Accept
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default Login;