import React from 'react';
import { Box, IconButton, Avatar, useMediaQuery, useTheme, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import WalletInfo from './WalletInfo';
import FrostedGlass from './FrostedGlass';

const CustomAppBar = ({ user }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <FrostedGlass
      intensity="medium"
      darkMode={true}
      isContainer={true}
      sx={{
        position: 'fixed',
        top: 0,
        left: isDesktop ? '275px' : 0,
        right: 0,
        zIndex: 1100,
        display: 'flex',
        flexDirection: 'column',
        backdropFilter: 'blur(10px)',
        backgroundColor: 'rgba(24, 24, 37, 0.8)',
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: 'calc(env(safe-area-inset-top) + 56px)',
          px: 2,
          paddingTop: 'env(safe-area-inset-top)',
        }}
      >
        {isDesktop && (
          <Typography variant="h6" component="div" sx={{ color: '#cdd6f4' }}>
          </Typography>
        )}
        <WalletInfo />
      </Box>
    </FrostedGlass>
  );
};

export default CustomAppBar;