import React, { useState, useEffect, useCallback, useRef } from 'react';
import { 
  Typography, 
  Box, 
  Avatar, 
  IconButton, 
  TextField, 
  Button, 
  Snackbar, 
  CircularProgress,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Input,
  InputAdornment,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { 
  ChatBubbleOutline, 
  Repeat, 
  FavoriteBorder, 
  Favorite, 
  IosShare, 
  Close as CloseIcon, 
  PhotoCamera, 
  RocketLaunch 
} from '@mui/icons-material';
import { useEthereumManager } from '../utils/eth';
import { usePrivyAuth } from '../firebase/auth';
import { firestore, serverTimestamp, storage } from '../firebase/firebase';
import { doc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { ethers } from 'ethers';
import { useWallets } from '@privy-io/react-auth';
import { encodeFunctionData, decodeEventLog } from 'viem';
import BANGER_ABI from '../contract/BangerABI.json';
import { useNavigate } from 'react-router-dom';
import FrostedGlass from './FrostedGlass';

const BANGER_ADDRESS = process.env.REACT_APP_BANGER_CONTRACT_ADDRESS;
const CHAIN_ID = process.env.REACT_APP_CHAIN_ID || '0x79a';
const API_URL = process.env.REACT_APP_SERVER_URL || 'https://inari-server-63b59ac586b0.herokuapp.com';

const Comment = ({ 
  postId, 
  uniqueId, 
  postOwner, 
  comment, 
  isNewComment = false, 
  allComments = [], 
  onClick, 
  onReply, 
  replyCount, 
  likes, 
  onLike, 
  currentUserId, 
  onQuote,
  setShowBottomNav,
  isComment = false
}) => {
  const navigate = useNavigate();
  const [replyText, setReplyText] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isBoosted, setIsBoosted] = useState(false);
  const { getHighestPaidCommentAmount, getDebugPriceAndFee, getBoostedCommentAmounts } = useEthereumManager();
  const { user, ready, authenticated, login } = usePrivyAuth();
  const { wallets } = useWallets();
  const [userAddress, setUserAddress] = useState(null);
  const [isInitializing, setIsInitializing] = useState(true);
  const [highestPaidAmount, setHighestPaidAmount] = useState('0');
  const [boostedAmount, setBoostedAmount] = useState(null);
  const hasLoadedCommentData = useRef(false);
  const [showFullPageReply, setShowFullPageReply] = useState(false);
  const textFieldRef = useRef(null);
  const fileInputRef = useRef(null);
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [showBoostDialog, setShowBoostDialog] = useState(false);
  const [boostInputValue, setBoostInputValue] = useState('');
  const [showReplyDialog, setShowReplyDialog] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    console.log('Comment component props:', { postId, uniqueId, postOwner, comment, isNewComment });

    const fetchHighestPaidAmount = async () => {
      if (uniqueId && !hasLoadedCommentData.current) {
        try {
          const amount = await getHighestPaidCommentAmount(uniqueId);
          console.log('Highest paid amount:', amount);
          setHighestPaidAmount(amount === "0.0" || !amount ? "0" : amount);
          hasLoadedCommentData.current = true;
        } catch (error) {
          console.error('Error fetching highest paid comment amount:', error);
          setHighestPaidAmount('0');
        }
      }
    };

    fetchHighestPaidAmount();
  }, [uniqueId, getHighestPaidCommentAmount]);

  useEffect(() => {
    const fetchUserAddress = async () => {
      if (ready && user && wallets.length > 0) {
        setUserAddress(wallets[0].address);
        console.log('User address set from wallet:', wallets[0].address);
      } else if (ready && user) {
        if (user.ethereumAddress) {
          setUserAddress(user.ethereumAddress);
          console.log('User address set from user object:', user.ethereumAddress);
        } else {
          try {
            const provider = await wallets[0].getEthereumProvider();
            const accounts = await provider.request({ method: 'eth_accounts' });
            if (accounts && accounts.length > 0) {
              setUserAddress(accounts[0]);
              console.log('User address set from provider:', accounts[0]);
            } else {
              console.log('No Ethereum accounts found');
            }
          } catch (error) {
            console.error('Error fetching user Ethereum address:', error);
          }
        }
      }
      setIsInitializing(false);
    };

    fetchUserAddress();
  }, [ready, user, wallets]);

  const fetchBoostedAmount = useCallback(async () => {
    if (comment && comment.isBoosted && !hasLoadedCommentData.current) {
      try {
        const amounts = await getBoostedCommentAmounts(uniqueId);
        const commentAmount = amounts.find(amount => amount.commentId === comment.uniqueCommentId);
        if (commentAmount) {
          setBoostedAmount(ethers.utils.formatEther(commentAmount.amount));
        }
        hasLoadedCommentData.current = true;
      } catch (error) {
        console.error('Error fetching boosted amount:', error);
      }
    }
  }, [comment, uniqueId, getBoostedCommentAmounts]);

  useEffect(() => {
    fetchBoostedAmount();
  }, [fetchBoostedAmount]);

  useEffect(() => {
    hasLoadedCommentData.current = false;
  }, [comment?.uniqueCommentId]);

  useEffect(() => {
    const handleResize = () => {
      const newKeyboardHeight = window.innerHeight - window.visualViewport.height;
      setKeyboardHeight(newKeyboardHeight);
    };

    window.visualViewport.addEventListener('resize', handleResize);
    return () => window.visualViewport.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (showFullPageReply || showReplyDialog) {
      setTimeout(() => {
        textFieldRef.current?.focus();
      }, 100);
    }
  }, [showFullPageReply, showReplyDialog]);

  const handleSubmitReply = async (e) => {
    e.preventDefault();
    if (!replyText.trim() && !image) {
      setError('Please enter some text or upload an image.');
      return;
    }
    if (loading) return;

    setLoading(true);
    try {
      if (!authenticated) {
        await login();
      }

      const result = await createCommentOnBlockchain(uniqueId, replyText, comment?.uniqueCommentId);
      console.log('Reply result:', result);

      if (!result.uniqueCommentId) {
        throw new Error('Failed to get uniqueCommentId from blockchain transaction');
      }

      let imageUrl = null;
      if (image) {
        const imageRef = ref(storage, `comments/${user.id}/${Date.now()}_${image.name}`);
        await uploadBytes(imageRef, image);
        imageUrl = await getDownloadURL(imageRef);
      }

      await addReplyToFirestore(result, replyText, comment?.uniqueCommentId, imageUrl);
      await createNotification(result.uniqueCommentId, comment ? comment.userId : postOwner);

      setReplyText('');
      setIsBoosted(false);
      setShowFullPageReply(false);
      setShowReplyDialog(false);
      setImage(null);
      setPreview(null);
    } catch (error) {
      console.error('Error adding reply:', error);
      setError(`Failed to add reply: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const createCommentOnBlockchain = async (uniquePostId, content, parentCommentId) => {
    if (wallets.length === 0) throw new Error("No wallets available");
  
    const wallet = wallets[0];
    const provider = await wallet.getEthereumProvider();
  
    try {
      const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, new ethers.providers.Web3Provider(provider).getSigner());
      const feeInWei = isBoosted ? 
        await contract.calculateFeeInWei(parseFloat(boostInputValue)) :
        await contract.calculateFeeInWei(1);  // 1 USD fee for regular comments
  
      console.log('Fee in Wei:', ethers.utils.formatEther(feeInWei), 'ETH');
  
      const debugInfo = await getDebugPriceAndFee();
      console.log('Debug Info:', debugInfo);
  
      let formattedParentCommentId = parentCommentId || ethers.constants.HashZero;
  
      const data = encodeFunctionData({
        abi: BANGER_ABI,
        functionName: isBoosted ? 'addBoostedComment' : 'addComment',
        args: isBoosted ? 
          [uniquePostId, content] : 
          [uniquePostId, content, formattedParentCommentId]
      });
  
      const transactionRequest = {
        to: BANGER_ADDRESS,
        data: data,
        value: ethers.utils.hexValue(feeInWei),
        chainId: CHAIN_ID,
        from: wallet.address,
      };
  
      const balance = await provider.request({
        method: 'eth_getBalance',
        params: [wallet.address, 'latest'],
      });
  
      console.log('User balance:', ethers.utils.formatEther(balance), 'ETH');
  
      if (ethers.BigNumber.from(balance).lt(feeInWei)) {
        throw new Error("Insufficient balance to pay the fee");
      }
  
      const txHash = await provider.request({
        method: 'eth_sendTransaction',
        params: [transactionRequest],
      });
  
      console.log(`Transaction sent. Hash: ${txHash}`);
  
      const receipt = await waitForTransaction(provider, txHash);
      console.log(`Transaction mined. Block number: ${receipt.blockNumber}`);
  
      const commentAddedEvent = receipt.logs.find(log => 
        log.address.toLowerCase() === BANGER_ADDRESS.toLowerCase()
      );
  
      if (!commentAddedEvent) {
        throw new Error("CommentAdded event not found in transaction logs");
      }
  
      const decodedEvent = decodeEventLog({
        abi: BANGER_ABI,
        data: commentAddedEvent.data,
        topics: commentAddedEvent.topics,
      });
  
      console.log('Decoded event:', decodedEvent);
  
      const uniqueCommentId = decodedEvent.args.uniqueCommentId;
      const commentId = decodedEvent.args.commentId.toString();
      const amountPaid = decodedEvent.args.amountPaid?.toString() ?? '0';
      const eventIsBoosted = decodedEvent.args.isBoosted ?? false;
  
      console.log('Extracted from event:', { uniqueCommentId, commentId, amountPaid, eventIsBoosted });
  
      if (!uniqueCommentId) {
        throw new Error('UniqueCommentId not found in decoded event');
      }
  
      return { txHash, commentId, uniqueCommentId, amountPaid, isBoosted: eventIsBoosted };
    } catch (error) {
      console.error("Error creating comment on blockchain:", error);
      if (error.code === 'INSUFFICIENT_FUNDS') {
        throw new Error("Insufficient funds to create comment");
      } else if (error.code === 'UNPREDICTABLE_GAS_LIMIT') {
        throw new Error("Unable to estimate gas. The transaction may fail or the contract conditions are not met.");
      } else {
        throw error;
      }
    }
  };

  const waitForTransaction = (provider, txHash) => {
    return new Promise((resolve, reject) => {
      const checkReceipt = async () => {
        try {
          const receipt = await provider.request({
            method: 'eth_getTransactionReceipt',
            params: [txHash],
          });
          if (receipt) {
            resolve(receipt);
          } else {
            setTimeout(checkReceipt, 1000);
          }
        } catch (error) {
          reject(error);
        }
      };
      checkReceipt();
    });
  };

  const addReplyToFirestore = async (result, content, parentCommentId, imageUrl) => {
    const replyData = {
      content,
      userId: user.id,
      userName: user.twitter?.username || user.email || 'Anonymous',
      userPhoto: user.twitter?.profilePictureUrl || user.profilePictureUrl || 'default-photo-url',
      createdAt: serverTimestamp(),
      parentCommentId: parentCommentId || null,
      uniqueCommentId: result.uniqueCommentId,
      commentId: result.commentId,
      transactionHash: result.txHash,
      isBoosted: result.isBoosted,
      amountPaid: result.amountPaid,
      imageUrl: imageUrl || null
    };
  
    await setDoc(doc(firestore, `posts/${postId}/comments`, result.uniqueCommentId), replyData);
  };

  const createNotification = async (commentId, recipientId) => {
    try {
      const response = await fetch(`${API_URL}/api/notifications`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'reply',
          actorId: user.id,
          recipientId: recipientId,
          postId: postId,
          commentId: commentId,
          content: `${user.twitter?.username || 'Someone'} replied to your comment`
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to create notification');
      }
    } catch (error) {
      console.error('Error creating notification:', error);
    }
  };
  
  const handleCommentClick = (e) => {
    e.stopPropagation();
    if (onClick) {
      onClick(comment);
    }
  };

  const handleOpenReply = () => {
    if (isDesktop) {
      setShowReplyDialog(true);
    } else {
      setShowFullPageReply(true);
    }
  };

  const handleCloseReply = () => {
    if (isDesktop) {
      setShowReplyDialog(false);
    } else {
      setShowFullPageReply(false);
    }
    setReplyText('');
    setIsBoosted(false);
    setImage(null);
    setPreview(null);
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const processedImage = await processImage(file);
        setImage(processedImage);
        setPreview(URL.createObjectURL(processedImage));
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const processImage = async (file) => {
    if (file.size > 5 * 1024 * 1024) {
      throw new Error('File size exceeds 5MB limit. Please choose a smaller file.');
    }

    if (!file.type.startsWith('image/')) {
      throw new Error('Please upload an image file.');
    }

    if (file.type === 'image/gif') {
      return file;
    }

    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        let { width, height } = img;
        
        if (width > 4096 || height > 4096) {
          if (width > height) {
            height *= 4096 / width;
            width = 4096;
          } else {
            width *= 4096 / height;
            height = 4096;
          }
        }

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(resolve, file.type, 0.95);
      };
      img.src = URL.createObjectURL(file);
    });
  };

  const handleBoostClick = () => {
    setShowBoostDialog(true);
  };

  const handleBoostDialogClose = () => {
    setShowBoostDialog(false);
    setBoostInputValue('');
  };

  const handleBoostConfirm = () => {
    const boostAmount = parseFloat(boostInputValue);
    if (!isNaN(boostAmount) && boostAmount > 0) {
      setIsBoosted(true);
    } else {
      setIsBoosted(false);
    }
    handleBoostDialogClose();
  };

  const renderReplyContent = () => (
    <>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
        <Avatar src={user?.twitter?.profilePictureUrl || 'https://via.placeholder.com/150'} sx={{ width: 40, height: 40, mr: 2 }} />
        <TextField
          inputRef={textFieldRef}
          placeholder="Write your reply..."
          value={replyText}
          onChange={(e) => setReplyText(e.target.value)}
          fullWidth
          multiline
          rows={4}
          variant="standard"
          InputProps={{ 
            disableUnderline: true,
            style: { color: '#cdd6f4' }
          }}
          autoFocus
        />
      </Box>
      {isBoosted && (
        <Chip
          label={`Boost: $${boostInputValue}`}
          color="primary"
          size="small"
          sx={{ mt: 1, mb: 2 }}
          onDelete={() => setIsBoosted(false)}
        />
      )}
      {preview && (
        <Box mb={2}>
          <img src={preview} alt="Preview" style={{ width: '100%', maxHeight: '200px', objectFit: 'cover', borderRadius: '8px' }} />
        </Box>
      )}
      {comment && (
        <Box sx={{ p: 2, backgroundColor: 'rgba(255, 255, 255, 0.05)', borderRadius: 2, mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <Avatar src={comment.userPhoto || 'https://via.placeholder.com/150'} sx={{ width: 24, height: 24, mr: 1 }} />
            <Box>
              <Typography variant="body2" sx={{ color: '#cdd6f4', fontWeight: 'bold' }}>
                @{comment.userName || 'Anonymous'}
              </Typography>
              <Typography variant="body2" sx={{ color: '#cdd6f4', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}>
                {comment.content}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );

  const renderReplyDialog = () => (
    <Dialog
      open={showReplyDialog}
      onClose={handleCloseReply}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        Reply
        <IconButton
          aria-label="close"
          onClick={handleCloseReply}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {renderReplyContent()}
      </DialogContent>
      <DialogActions>
        <input
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          ref={fileInputRef}
          onChange={handleImageChange}
        />
        <IconButton color="primary" onClick={handleImageClick}>
          <PhotoCamera />
        </IconButton>
        <IconButton color="primary" onClick={handleBoostClick}>
          <RocketLaunch />
        </IconButton>
        <Button onClick={handleCloseReply}>Cancel</Button>
        <Button 
          onClick={handleSubmitReply}
          disabled={loading || (!replyText.trim() && !image)}
        >
          {loading ? <CircularProgress size={24} /> : 'Reply'}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderFullPageReply = () => (
    <Box sx={{ 
      height: '100vh', 
      display: 'flex', 
      flexDirection: 'column',
      bgcolor: 'background.default',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1300,
    }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        p: 2,
        pt: 'calc(env(safe-area-inset-top) + 8px)',
        pb: 1,
        borderBottom: 1,
        borderColor: 'divider',
        backgroundColor: 'background.default',
      }}>
        <IconButton onClick={handleCloseReply} aria-label="close">
          <CloseIcon />
        </IconButton>
        <Typography variant="h6">Reply</Typography>
        <Button 
          onClick={handleSubmitReply}
          disabled={loading || (!replyText.trim() && !image)}
          sx={{ color: 'primary.main' }}
        >
          {loading ? <CircularProgress size={24} /> : 'Reply'}
        </Button>
      </Box>
      <Box sx={{ 
        flexGrow: 1, 
        display: 'flex', 
        flexDirection: 'column', 
        p: 2, 
        overflow: 'auto',
        paddingBottom: '60px',
      }}>
        {renderReplyContent()}
      </Box>
      <Box sx={{ 
        position: 'fixed', 
        bottom: `calc(${keyboardHeight}px + env(safe-area-inset-bottom))`, 
        left: 0, 
        right: 0, 
        borderTop: 1, 
        borderColor: 'divider', 
        p: 2, 
        display: 'flex', 
        alignItems: 'center',
        backgroundColor: 'background.paper',
        zIndex: 1000,
        transition: 'bottom 0.3s'
      }}>
        <input
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          ref={fileInputRef}
          onChange={handleImageChange}
        />
        <IconButton color="primary" onClick={handleImageClick}>
          <PhotoCamera />
        </IconButton>
        <IconButton color="primary" onClick={handleBoostClick}>
          <RocketLaunch />
        </IconButton>
      </Box>
    </Box>
  );

  if (isInitializing) {
    return <Typography>Initializing...</Typography>;
  }

  if (!postId) {
    return <Typography>Missing post information</Typography>;
  }

  if (!comment) {
    return null;
  }

  return (
    <>
      <FrostedGlass 
        darkMode 
        intensity="medium" 
        isTransparent={true}
        sx={{ 
          mb: 2,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
          },
          ...(isComment && {
            transform: 'scale(0.98)', // Slightly smaller for comments
          }),
        }}
        onClick={handleCommentClick}
      >
        <Box sx={{ p: isComment ? 1.5 : 2 }}> {/* Reduce padding for comments */}
          <Box sx={{ display: 'flex', mb: 2 }}>
            <Avatar 
              sx={{ 
                width: isComment ? 32 : 40, 
                height: isComment ? 32 : 40, 
                mr: 2 
              }} 
              src={comment.userPhoto || 'default-avatar-url'} 
            />
            <Box sx={{ flexGrow: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mr: 1, color: '#f9e2af' }}>
                  @{comment.userName || 'Anonymous'}
                </Typography>
                <Typography variant="caption" sx={{ color: '#7f849c' }}>
                  · {comment.createdAt ? new Date(comment.createdAt.seconds * 1000).toLocaleString() : 'Date unavailable'}
                </Typography>
                {comment.isBoosted && (
                  <Typography variant="caption" sx={{ color: '#fab387', ml: 1 }}>
                    {boostedAmount} ETH
                  </Typography>
                )}
              </Box>
              <Typography variant="body2" sx={{ mb: 1, color: '#cdd6f4', whiteSpace: 'pre-wrap' }}>
                {comment.content || 'No content'}
              </Typography>
              {comment.imageUrl && (
                <Box 
                  component="img"
                  src={comment.imageUrl}
                  sx={{ width: '100%', borderRadius: 2, mb: 2 }}
                  alt="Comment content"
                  loading="lazy"
                />
              )}
            </Box>
          </Box>
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
            <IconButton size="small" onClick={(e) => { e.stopPropagation(); handleOpenReply(); }}>
              <ChatBubbleOutline fontSize="small" sx={{ color: '#7f849c' }} />
              <Typography variant="caption" sx={{ ml: 0.5, color: '#7f849c' }}>
                {replyCount}
              </Typography>
            </IconButton>
            <IconButton size="small" onClick={(e) => { e.stopPropagation(); onQuote(comment); }}>
              <Repeat fontSize="small" sx={{ color: '#7f849c' }} />
            </IconButton>
            <IconButton size="small" onClick={(e) => { e.stopPropagation(); onLike(comment.uniqueCommentId); }}>
              {likes.users.includes(currentUserId) ? (
                <Favorite fontSize="small" sx={{ color: '#f38ba8' }} />
              ) : (
                <FavoriteBorder fontSize="small" sx={{ color: '#7f849c' }} />
              )}
              <Typography variant="caption" sx={{ ml: 0.5, color: '#7f849c' }}>
                {likes.count}
              </Typography>
            </IconButton>
            <IconButton size="small" onClick={(e) => { e.stopPropagation(); /* Handle share action */ }}>
              <IosShare fontSize="small" sx={{ color: '#7f849c' }} />
            </IconButton>
          </Box>
        </Box>
      </FrostedGlass>
      {isDesktop ? renderReplyDialog() : null}
      {!isDesktop && showFullPageReply && renderFullPageReply()}
      <Dialog open={showBoostDialog} onClose={handleBoostDialogClose}>
        <DialogTitle>Boost your reply</DialogTitle>
        <DialogContent>
          <Input
            autoFocus
            type="number"
            value={boostInputValue}
            onChange={(e) => setBoostInputValue(e.target.value)}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            inputProps={{ min: 0, step: 0.01 }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBoostDialogClose}>Cancel</Button>
          <Button onClick={handleBoostConfirm}>Set</Button>
          </DialogActions>
      </Dialog>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
    </>
  );
};

export default React.memo(Comment);