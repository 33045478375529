import React, { useState, useEffect, useRef } from 'react';
import { 
  TextField, 
  Button, 
  Box, 
  Typography, 
  Avatar, 
  CircularProgress, 
  Snackbar,
  AppBar,
  Toolbar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { useEthereumManager } from '../utils/eth';
import { usePrivyAuth } from '../firebase/auth';
import { firestore, serverTimestamp, storage } from '../firebase/firebase';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { ethers } from 'ethers';
import { useWallets } from '@privy-io/react-auth';
import { encodeFunctionData, decodeEventLog } from 'viem';
import BANGER_ABI from '../contract/BangerABI.json';
import { useNavigate } from 'react-router-dom';
import FrostedGlass from './FrostedGlass';

const BANGER_ADDRESS = process.env.REACT_APP_BANGER_CONTRACT_ADDRESS;
const CHAIN_ID = process.env.REACT_APP_CHAIN_ID || '0x79a';
const API_URL = process.env.REACT_APP_SERVER_URL || 'https://inari-server-63b59ac586b0.herokuapp.com';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const MAX_DIMENSION = 4096; // Twitter's max dimension

const processImage = async (file) => {
  if (file.size > MAX_FILE_SIZE) {
    throw new Error('File size exceeds 5MB limit. Please choose a smaller file.');
  }

  if (!file.type.startsWith('image/')) {
    throw new Error('Please upload an image file.');
  }

  if (file.type === 'image/gif') {
    return file;
  }

  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      let { width, height } = img;
      
      if (width > MAX_DIMENSION || height > MAX_DIMENSION) {
        if (width > height) {
          height *= MAX_DIMENSION / width;
          width = MAX_DIMENSION;
        } else {
          width *= MAX_DIMENSION / height;
          height = MAX_DIMENSION;
        }
      }

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(resolve, file.type, 0.95);
    };
    img.src = URL.createObjectURL(file);
  });
};

const QuoteTweet = ({ postToQuote, onClose, onSuccess }) => {
  const [quoteText, setQuoteText] = useState('');
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getDebugPriceAndFee } = useEthereumManager();
  const { user, ready, authenticated, login } = usePrivyAuth();
  const { wallets } = useWallets();
  const [userAddress, setUserAddress] = useState(null);
  const navigate = useNavigate();
  const textFieldRef = useRef(null);
  const fileInputRef = useRef(null);
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    const fetchUserAddress = async () => {
      if (ready && user && wallets.length > 0) {
        setUserAddress(wallets[0].address);
      }
    };

    fetchUserAddress();

    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }

    const handleResize = () => {
      const newKeyboardHeight = window.innerHeight - window.visualViewport.height;
      setKeyboardHeight(newKeyboardHeight);
    };

    window.visualViewport.addEventListener('resize', handleResize);
    return () => window.visualViewport.removeEventListener('resize', handleResize);
  }, [ready, user, wallets]);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const processedImage = await processImage(file);
        setImage(processedImage);
        setPreview(URL.createObjectURL(processedImage));
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const createNotification = async (quotePostId, originalPostOwner) => {
    try {
      const response = await fetch(`${API_URL.replace(/\/$/, '')}/api/notifications`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'quote',
          actorId: user.id,
          recipientId: originalPostOwner,
          postId: quotePostId,
          content: `${user.twitter?.username || 'Someone'} quoted your post`
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to create notification');
      }
    } catch (error) {
      console.error('Error creating notification:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!quoteText.trim() && !image) {
      setError('Please enter some text or upload an image.');
      return;
    }
    if (!userAddress) {
      setError('User address is missing. Please ensure you are logged in with an Ethereum wallet.');
      return;
    }
  
    setLoading(true);
    setError(null);
  
    try {
      if (!authenticated) {
        await login();
      }
  
      const result = await createQuoteTweetOnBlockchain(postToQuote.uniqueId, quoteText);
  
      if (!result.uniqueId) {
        throw new Error('Failed to get uniqueId from blockchain transaction');
      }
  
      let imageUrl = null;
      if (image) {
        const imageRef = ref(storage, `posts/${user.id}/${Date.now()}_${image.name}`);
        await uploadBytes(imageRef, image);
        imageUrl = await getDownloadURL(imageRef);
      }
  
      const quotePostData = {
        text: quoteText,
        ...(imageUrl && { imageUrl }),
        originalPostId: postToQuote.id,
        originalPostText: postToQuote.text || postToQuote.content || '',
        originalUserName: postToQuote.userName || 'Anonymous',
        originalUserPhoto: postToQuote.userPhoto || 'default-photo-url',
        originalPostImageUrl: postToQuote.imageUrl || null,
        createdAt: serverTimestamp(),
        userId: user.id,
        userName: user.twitter?.username || user.email || 'Anonymous',
        userPhoto: user.twitter?.profilePictureUrl || user.profilePictureUrl || 'default-photo-url',
        isQuoteTweet: true,
        postId: result.postId,
        uniqueId: result.uniqueId,
        transactionHash: result.txHash
      };
  
      const quotePostRef = await addDoc(collection(firestore, 'posts'), quotePostData);
      await createNotification(quotePostRef.id, postToQuote.userId);
  
      setQuoteText('');
      setImage(null);
      setPreview(null);
      onSuccess();
    } catch (error) {
      console.error('Error adding quote tweet:', error);
      setError(`Failed to add quote tweet: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const createQuoteTweetOnBlockchain = async (originalUniqueId, content) => {
    if (wallets.length === 0) throw new Error("No wallets available");
    
    const wallet = wallets[0];
    const provider = await wallet.getEthereumProvider();
    
    try {
      console.log('Starting quoteTweet function');
      const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, new ethers.providers.Web3Provider(provider).getSigner());
  
      // Check if this is a self-quote
      const originalPost = await contract.getPostByUniqueId(originalUniqueId);
      const originalComment = await contract.getCommentByUniqueId(originalUniqueId);
      const isComment = originalComment.timestamp > 0;
      const isSelfQuote = (isComment && originalComment.commenter === wallet.address) || (!isComment && originalPost.author === wallet.address);
  
      console.log('Is self quote:', isSelfQuote);
  
      let feeInWei = ethers.BigNumber.from(0);
      if (!isSelfQuote) {
        feeInWei = await contract.calculateFeeInWei(1); // 1 USD fee for non-self quotes
        console.log('Fee in Wei:', ethers.utils.formatEther(feeInWei), 'ETH');
      }
  
      const debugInfo = await getDebugPriceAndFee();
      console.log('Debug Info:', debugInfo);
  
      const data = encodeFunctionData({
        abi: BANGER_ABI,
        functionName: 'quoteTweet',
        args: [originalUniqueId, content]
      });
  
      const transactionRequest = {
        to: BANGER_ADDRESS,
        data: data,
        value: ethers.utils.hexValue(feeInWei),
        chainId: CHAIN_ID,
        from: wallet.address,
      };
  
      const balance = await provider.request({
        method: 'eth_getBalance',
        params: [wallet.address, 'latest'],
      });
  
      console.log('User balance:', ethers.utils.formatEther(balance), 'ETH');
  
      if (ethers.BigNumber.from(balance).lt(feeInWei)) {
        throw new Error("Insufficient balance to pay the fee");
      }
  
      const txHash = await provider.request({
        method: 'eth_sendTransaction',
        params: [transactionRequest],
      });
  
      console.log(`Transaction sent. Hash: ${txHash}`);
  
      const receipt = await waitForTransaction(provider, txHash);
      console.log(`Transaction mined. Block number: ${receipt.blockNumber}`);
  
      const quoteTweetCreatedEvent = receipt.logs.find(log => 
        log.address.toLowerCase() === BANGER_ADDRESS.toLowerCase()
      );
  
      if (!quoteTweetCreatedEvent) {
        throw new Error("QuoteTweetCreated event not found in transaction logs");
      }
  
      const decodedEvent = decodeEventLog({
        abi: BANGER_ABI,
        data: quoteTweetCreatedEvent.data,
        topics: quoteTweetCreatedEvent.topics,
      });
  
      console.log('Decoded QuoteTweetCreated event:', decodedEvent);
  
      if (!decodedEvent.args) {
        throw new Error('Decoded event args not found');
      }
  
      const uniqueId = decodedEvent.args.uniqueId;
      const postId = decodedEvent.args.postId.toString();
  
      console.log('Extracted from event:', { uniqueId, postId });
  
      if (!uniqueId) {
        throw new Error('UniqueId not found in decoded event');
      }
  
      return { txHash, postId, uniqueId };
    } catch (error) {
      console.error("Error quote tweeting:", error);
      throw error;
    }
  };

  const waitForTransaction = (provider, txHash) => {
    return new Promise((resolve, reject) => {
      const checkReceipt = async () => {
        try {
          const receipt = await provider.request({
            method: 'eth_getTransactionReceipt',
            params: [txHash],
          });
          if (receipt) {
            resolve(receipt);
          } else {
            setTimeout(checkReceipt, 1000);
          }
        } catch (error) {
          reject(error);
        }
      };
      checkReceipt();
    });
  };

  const renderQuotedPost = (item) => {
    return (
      <FrostedGlass 
        darkMode 
        intensity="medium" 
        sx={{ 
          borderRadius: '8px',
          position: 'relative',
          mb: 2
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <Avatar src={item.userPhoto || 'https://via.placeholder.com/150'} sx={{ width: 40, height: 40, mr: 2 }} />
          <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#f9e2af' }}>
              @{item.userName || 'Anonymous'}
            </Typography>
            <Typography variant="body2" sx={{ color: '#cdd6f4', whiteSpace: 'pre-wrap', mb: 1 }}>
              {item.content || item.text}
            </Typography>
            {item.imageUrl && (
              <Box 
                component="img"
                src={item.imageUrl}
                sx={{ width: '100%', maxHeight: '150px', objectFit: 'cover', borderRadius: 1 }}
                alt="Post content"
                loading="lazy"
              />
            )}
            </Box>
        </Box>
      </FrostedGlass>
    );
  };

  const renderContent = () => (
    <>
      <TextField
        inputRef={textFieldRef}
        placeholder="Add your quote..."
        value={quoteText}
        onChange={(e) => setQuoteText(e.target.value)}
        fullWidth
        multiline
        rows={4}
        variant="standard"
        InputProps={{ 
          disableUnderline: true,
          style: { color: '#cdd6f4' }
        }}
        sx={{ mb: 2 }}
      />
      {preview && (
        <Box mb={2}>
          <img src={preview} alt="Preview" style={{ width: '100%', maxHeight: '200px', objectFit: 'cover', borderRadius: '8px' }} />
        </Box>
      )}
      {renderQuotedPost(postToQuote)}
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleImageChange}
      />
      <IconButton color="primary" onClick={handleImageClick}>
        <PhotoCamera />
      </IconButton>
    </>
  );

  if (isDesktop) {
    return (
      <Dialog
        open={true}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Quote Tweet
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {renderContent()}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} disabled={loading || (!quoteText.trim() && !image)}>
            {loading ? <CircularProgress size={24} /> : 'Quote'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Box sx={{ 
      height: '100vh', 
      display: 'flex', 
      flexDirection: 'column', 
      bgcolor: 'background.default',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1300,
    }}>
      <AppBar 
        position="static" 
        elevation={0}
        sx={{
          pt: 'env(safe-area-inset-top)',
          backgroundColor: 'background.default',
        }}
      >
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Quote Tweet
          </Typography>
          <Button 
            color="inherit" 
            onClick={handleSubmit}
            disabled={loading || (!quoteText.trim() && !image)}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Quote'}
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ 
        flexGrow: 1, 
        display: 'flex', 
        flexDirection: 'column', 
        p: 2, 
        overflow: 'auto',
        pb: 'calc(60px + env(safe-area-inset-bottom))',
      }}>
        {renderContent()}
      </Box>
      <Box sx={{ 
        position: 'fixed', 
        bottom: `calc(${keyboardHeight}px + env(safe-area-inset-bottom))`, 
        left: 0, 
        right: 0, 
        borderTop: 1, 
        borderColor: 'divider', 
        p: 2, 
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'background.paper',
        zIndex: 1000,
        transition: 'bottom 0.3s'
      }}>
        {/* Add any additional controls here if needed */}
      </Box>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
    </Box>
  );
};

export default QuoteTweet;