import React, { useEffect, useState } from 'react';
import { Typography, List, ListItem, ListItemText, ListItemAvatar, Avatar, Box, CircularProgress, Alert, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { usePrivyAuth } from '../firebase/auth';
import { formatDistanceToNow } from 'date-fns';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const API_URL = process.env.REACT_APP_SERVER_URL || 'https://inari-server-63b59ac586b0.herokuapp.com';

const Notifications = () => {
  const { user } = usePrivyAuth();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      if (!user || !user.id) {
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        setError(null);
        const response = await fetch(`${API_URL.replace(/\/$/, '')}/api/notifications/${user.id}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Fetched notifications:', data);
        setNotifications(data);
      } catch (error) {
        console.error('Error fetching notifications:', error);
        setError('Failed to fetch notifications. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [user]);

  const handleDeleteNotification = async (notificationId) => {
    try {
      const response = await fetch(`${API_URL.replace(/\/$/, '')}/api/notifications/${notificationId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ privyUserId: user.id }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setNotifications(notifications.filter(notification => notification.id !== notificationId));
    } catch (error) {
      console.error('Error deleting notification:', error);
      setError('Failed to delete notification. Please try again later.');
    }
  };

  const formatDate = (dateValue) => {
    if (!dateValue) return 'Date unavailable';

    try {
      let date;
      if (typeof dateValue === 'object' && '_seconds' in dateValue) {
        // Firestore Timestamp
        date = new Date(dateValue._seconds * 1000 + dateValue._nanoseconds / 1000000);
      } else if (typeof dateValue === 'object' && dateValue.toDate) {
        // Firestore Timestamp (alternative format)
        date = dateValue.toDate();
      } else if (typeof dateValue === 'string') {
        // ISO string
        date = new Date(dateValue);
      } else if (typeof dateValue === 'number') {
        // Unix timestamp (milliseconds)
        date = new Date(dateValue);
      } else {
        throw new Error('Unsupported date format');
      }

      return formatDistanceToNow(date, { addSuffix: true });
    } catch (error) {
      console.error('Error formatting date:', error, 'Date value:', dateValue);
      return 'Invalid date';
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>Notifications</Typography>
      {notifications.length === 0 ? (
        <Typography>No notifications yet.</Typography>
      ) : (
        <List>
          {notifications.map((notification) => (
            <ListItem 
              key={notification.id} 
              alignItems="flex-start"
              secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteNotification(notification.id)}>
                  <DeleteOutlineIcon />
                </IconButton>
              }
            >
              <Link 
                to={notification.type === 'comment' ? `/post/${notification.postId}/comment/${notification.commentId}` : `/${notification.actorName}`}
                style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
              >
                <ListItemAvatar>
                  <Avatar 
                    src={notification.actorAvatar} 
                    alt={notification.actorName || 'User'}
                  >
                    {!notification.actorAvatar && notification.actorName ? notification.actorName[0].toUpperCase() : null}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body1"
                        color="primary"
                        sx={{ mr: 1 }}
                      >
                        {notification.actorName}
                      </Typography>
                      {notification.content}
                    </React.Fragment>
                  }
                  secondary={formatDate(notification.createdAt)}
                />
              </Link>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default Notifications;