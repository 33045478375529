import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => 
    !['intensity', 'isDarkMode', 'isTransparent', 'outlineColor', 'isContainer'].includes(prop)
})(({ theme, intensity, isDarkMode, isTransparent, outlineColor, isContainer }) => {
  const getBackgroundColor = () => {
    if (isDarkMode) {
      return '#181825'; // Changed background color when in dark mode
    } else {
      return intensity === 'low' ? 'rgba(255, 255, 255, 0.8)' :
             intensity === 'medium' ? 'rgba(255, 255, 255, 0.9)' :
             'rgba(255, 255, 255, 1)';
    }
  };

  const getBorderColor = () => {
    if (outlineColor) {
      return outlineColor;
    }
    return isDarkMode ? 'rgba(180, 190, 254, 0.2)' : 'rgba(0, 0, 0, 0.2)';
  };

  return {
    background: isTransparent ? 'transparent' : getBackgroundColor(),
    backdropFilter: isTransparent ? 'none' : 'blur(10px)',
    borderRadius: isContainer ? '0' : '12px',
    border: isContainer ? 'none' : `1px solid ${getBorderColor()}`,
    boxShadow: isContainer || isTransparent ? 'none' : `0 4px 30px ${isDarkMode ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)'}`,
    transition: 'all 0.3s ease-in-out',
    padding: isContainer ? '0' : '12px',
    marginBottom: isContainer ? '0' : '8px',
    '&:hover': {
      boxShadow: isContainer || isTransparent ? 'none' : `0 6px 40px ${isDarkMode ? 'rgba(0, 0, 0, 0.2)' : 'rgba(180, 190, 254, 0.2)'}`,
    },
  };
});

const FrostedGlass = React.forwardRef(({ 
  intensity = 'medium', 
  darkMode = true, 
  isTransparent = false, 
  outlineColor, 
  isContainer = false, 
  ...props 
}, ref) => {
  return (
    <StyledBox
      ref={ref}
      intensity={intensity}
      isDarkMode={darkMode}
      isTransparent={isTransparent}
      outlineColor={outlineColor}
      isContainer={isContainer}
      {...props}
    />
  );
});

export default FrostedGlass;
