import React, { useEffect } from 'react';
import { usePrivyAuth } from '../firebase/auth';

const API_URL = process.env.REACT_APP_SERVER_URL || 'https://inari-server-63b59ac586b0.herokuapp.com';

const PushNotificationSubscriber = () => {
  const { user, authenticated } = usePrivyAuth();

  const subscribeToPush = async () => {
    if (!authenticated || !user) return;

    try {
      const registration = await navigator.serviceWorker.ready;
      let subscription = await registration.pushManager.getSubscription();

      if (!subscription) {
        const publicVapidKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;
        subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
        });
      }

      // Send the subscription to your server
      const response = await fetch(`${API_URL}/api/save-push-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          privyUserId: user.id,
          subscription: subscription
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      console.log('Push notification subscription successful');
    } catch (error) {
      console.error('Error subscribing to push notifications:', error);
    }
  };

  useEffect(() => {
    if (authenticated && user && Notification.permission === 'granted') {
      subscribeToPush();
    }
  }, [authenticated, user]);

  return null;
};

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0, rawDataLength = rawData.length; i < rawDataLength; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export default PushNotificationSubscriber;