import { ethers } from 'ethers';
import { usePrivy, useWallets } from '@privy-io/react-auth';
import { encodeFunctionData, decodeEventLog } from 'viem';
import { formatEther } from 'ethers/lib/utils';


const BANGER_ADDRESS = process.env.REACT_APP_BANGER_CONTRACT_ADDRESS;
const CHAIN_ID = process.env.REACT_APP_CHAIN_ID || '0x79a';

const BANGER_ABI = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_protocolFeeDestination",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "_initialUsdtPrice",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_initialEthPrice",
                "type": "uint256"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "uniquePostId",
                "type": "bytes32"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "BoostedCommentAdded",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "commenter",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "postOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "postId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "commentId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "bytes32",
                "name": "uniqueCommentId",
                "type": "bytes32"
            },
            {
                "indexed": false,
                "internalType": "string",
                "name": "content",
                "type": "string"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amountPaid",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "bool",
                "name": "isBoosted",
                "type": "bool"
            },
            {
                "indexed": false,
                "internalType": "bytes32",
                "name": "parentCommentId",
                "type": "bytes32"
            }
        ],
        "name": "CommentAdded",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "uniquePostId",
                "type": "bytes32"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "newHighestAmount",
                "type": "uint256"
            }
        ],
        "name": "HighestPaidCommentUpdated",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "author",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "postId",
                "type": "uint256"
            },
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "uniqueId",
                "type": "bytes32"
            },
            {
                "indexed": false,
                "internalType": "string",
                "name": "content",
                "type": "string"
            },
            {
                "indexed": false,
                "internalType": "enum Banger.PostType",
                "name": "postType",
                "type": "uint8"
            }
        ],
        "name": "PostCreated",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "author",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "postId",
                "type": "uint256"
            },
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "uniqueId",
                "type": "bytes32"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "originalPoster",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "originalPostId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "string",
                "name": "content",
                "type": "string"
            },
            {
                "indexed": false,
                "internalType": "bool",
                "name": "isComment",
                "type": "bool"
            }
        ],
        "name": "QuoteTweetCreated",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "trader",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "bytes32",
                "name": "postUniqueId",
                "type": "bytes32"
            },
            {
                "indexed": false,
                "internalType": "bool",
                "name": "isBuy",
                "type": "bool"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "shareAmount",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "ethAmount",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "protocolEthAmount",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "subjectEthAmount",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "supply",
                "type": "uint256"
            }
        ],
        "name": "Trade",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "uniquePostId",
                "type": "bytes32"
            },
            {
                "internalType": "string",
                "name": "content",
                "type": "string"
            }
        ],
        "name": "addBoostedComment",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "uniquePostId",
                "type": "bytes32"
            },
            {
                "internalType": "string",
                "name": "content",
                "type": "string"
            },
            {
                "internalType": "bytes32",
                "name": "parentCommentId",
                "type": "bytes32"
            }
        ],
        "name": "addComment",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "boostedCommentAmounts",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "postUniqueId",
                "type": "bytes32"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "buyShares",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "usdAmount",
                "type": "uint256"
            }
        ],
        "name": "calculateFeeInWei",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "commentReplies",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "comments",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
            },
            {
                "internalType": "bytes32",
                "name": "uniqueId",
                "type": "bytes32"
            },
            {
                "internalType": "address",
                "name": "commenter",
                "type": "address"
            },
            {
                "internalType": "string",
                "name": "content",
                "type": "string"
            },
            {
                "internalType": "uint256",
                "name": "timestamp",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "amountPaid",
                "type": "uint256"
            },
            {
                "internalType": "bool",
                "name": "isBoosted",
                "type": "bool"
            },
            {
                "internalType": "bytes32",
                "name": "parentPostId",
                "type": "bytes32"
            },
            {
                "internalType": "bytes32",
                "name": "parentCommentId",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "name": "commentsByUniqueId",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
            },
            {
                "internalType": "bytes32",
                "name": "uniqueId",
                "type": "bytes32"
            },
            {
                "internalType": "address",
                "name": "commenter",
                "type": "address"
            },
            {
                "internalType": "string",
                "name": "content",
                "type": "string"
            },
            {
                "internalType": "uint256",
                "name": "timestamp",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "amountPaid",
                "type": "uint256"
            },
            {
                "internalType": "bool",
                "name": "isBoosted",
                "type": "bool"
            },
            {
                "internalType": "bytes32",
                "name": "parentPostId",
                "type": "bytes32"
            },
            {
                "internalType": "bytes32",
                "name": "parentCommentId",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "content",
                "type": "string"
            }
        ],
        "name": "createPost",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "debugPriceAndFee",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "usdtPrice",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "ethPrice",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "feeInWei",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "ethPriceInUsd",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32[]",
                "name": "postUniqueIds",
                "type": "bytes32[]"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "getBatchBuyPrices",
        "outputs": [
            {
                "internalType": "uint256[]",
                "name": "",
                "type": "uint256[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "uniquePostId",
                "type": "bytes32"
            }
        ],
        "name": "getBoostedCommentAmounts",
        "outputs": [
            {
                "internalType": "uint256[]",
                "name": "",
                "type": "uint256[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "postUniqueId",
                "type": "bytes32"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "getBuyPrice",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "uniqueId",
                "type": "bytes32"
            }
        ],
        "name": "getCommentByUniqueId",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "id",
                        "type": "uint256"
                    },
                    {
                        "internalType": "bytes32",
                        "name": "uniqueId",
                        "type": "bytes32"
                    },
                    {
                        "internalType": "address",
                        "name": "commenter",
                        "type": "address"
                    },
                    {
                        "internalType": "string",
                        "name": "content",
                        "type": "string"
                    },
                    {
                        "internalType": "uint256",
                        "name": "timestamp",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountPaid",
                        "type": "uint256"
                    },
                    {
                        "internalType": "bool",
                        "name": "isBoosted",
                        "type": "bool"
                    },
                    {
                        "internalType": "bytes32",
                        "name": "parentPostId",
                        "type": "bytes32"
                    },
                    {
                        "internalType": "bytes32",
                        "name": "parentCommentId",
                        "type": "bytes32"
                    }
                ],
                "internalType": "struct Banger.Comment",
                "name": "",
                "type": "tuple"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "uniqueCommentId",
                "type": "bytes32"
            }
        ],
        "name": "getCommentReplies",
        "outputs": [
            {
                "internalType": "uint256[]",
                "name": "",
                "type": "uint256[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "uniquePostId",
                "type": "bytes32"
            }
        ],
        "name": "getComments",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "id",
                        "type": "uint256"
                    },
                    {
                        "internalType": "bytes32",
                        "name": "uniqueId",
                        "type": "bytes32"
                    },
                    {
                        "internalType": "address",
                        "name": "commenter",
                        "type": "address"
                    },
                    {
                        "internalType": "string",
                        "name": "content",
                        "type": "string"
                    },
                    {
                        "internalType": "uint256",
                        "name": "timestamp",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amountPaid",
                        "type": "uint256"
                    },
                    {
                        "internalType": "bool",
                        "name": "isBoosted",
                        "type": "bool"
                    },
                    {
                        "internalType": "bytes32",
                        "name": "parentPostId",
                        "type": "bytes32"
                    },
                    {
                        "internalType": "bytes32",
                        "name": "parentCommentId",
                        "type": "bytes32"
                    }
                ],
                "internalType": "struct Banger.Comment[]",
                "name": "",
                "type": "tuple[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "uniquePostId",
                "type": "bytes32"
            }
        ],
        "name": "getCommentsCount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getEthPrice",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "uniquePostId",
                "type": "bytes32"
            }
        ],
        "name": "getHighestPaidCommentAmount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "uniqueId",
                "type": "bytes32"
            }
        ],
        "name": "getPostByUniqueId",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "id",
                        "type": "uint256"
                    },
                    {
                        "internalType": "bytes32",
                        "name": "uniqueId",
                        "type": "bytes32"
                    },
                    {
                        "internalType": "string",
                        "name": "content",
                        "type": "string"
                    },
                    {
                        "internalType": "address",
                        "name": "author",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "timestamp",
                        "type": "uint256"
                    },
                    {
                        "internalType": "enum Banger.PostType",
                        "name": "postType",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint256",
                        "name": "supply",
                        "type": "uint256"
                    }
                ],
                "internalType": "struct Banger.Post",
                "name": "",
                "type": "tuple"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "postUniqueId",
                "type": "bytes32"
            }
        ],
        "name": "getPostSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "supply",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "getPrice",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "pure",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "postUniqueId",
                "type": "bytes32"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "getSellPrice",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "postUniqueId",
                "type": "bytes32"
            },
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "getSharesBalance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getUsdtPrice",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "getUserPostCount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "getUserPosts",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "id",
                        "type": "uint256"
                    },
                    {
                        "internalType": "bytes32",
                        "name": "uniqueId",
                        "type": "bytes32"
                    },
                    {
                        "internalType": "string",
                        "name": "content",
                        "type": "string"
                    },
                    {
                        "internalType": "address",
                        "name": "author",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "timestamp",
                        "type": "uint256"
                    },
                    {
                        "internalType": "enum Banger.PostType",
                        "name": "postType",
                        "type": "uint8"
                    },
                    {
                        "internalType": "uint256",
                        "name": "supply",
                        "type": "uint256"
                    }
                ],
                "internalType": "struct Banger.Post[]",
                "name": "",
                "type": "tuple[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "name": "highestPaidCommentAmount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "posts",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
            },
            {
                "internalType": "bytes32",
                "name": "uniqueId",
                "type": "bytes32"
            },
            {
                "internalType": "string",
                "name": "content",
                "type": "string"
            },
            {
                "internalType": "address",
                "name": "author",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "timestamp",
                "type": "uint256"
            },
            {
                "internalType": "enum Banger.PostType",
                "name": "postType",
                "type": "uint8"
            },
            {
                "internalType": "uint256",
                "name": "supply",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "name": "postsByUniqueId",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
            },
            {
                "internalType": "bytes32",
                "name": "uniqueId",
                "type": "bytes32"
            },
            {
                "internalType": "string",
                "name": "content",
                "type": "string"
            },
            {
                "internalType": "address",
                "name": "author",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "timestamp",
                "type": "uint256"
            },
            {
                "internalType": "enum Banger.PostType",
                "name": "postType",
                "type": "uint8"
            },
            {
                "internalType": "uint256",
                "name": "supply",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "protocolFeeDestination",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "protocolFeePercent",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "originalUniqueId",
                "type": "bytes32"
            },
            {
                "internalType": "string",
                "name": "content",
                "type": "string"
            }
        ],
        "name": "quoteTweet",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "quoteTweets",
        "outputs": [
            {
                "internalType": "address",
                "name": "originalPoster",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "originalPostId",
                "type": "uint256"
            },
            {
                "internalType": "bool",
                "name": "isComment",
                "type": "bool"
            },
            {
                "internalType": "bytes32",
                "name": "originalUniqueId",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "postUniqueId",
                "type": "bytes32"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "sellShares",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_newEthPrice",
                "type": "uint256"
            }
        ],
        "name": "setEthPrice",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_feePercent",
                "type": "uint256"
            }
        ],
        "name": "setProtocolFeePercent",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_feePercent",
                "type": "uint256"
            }
        ],
        "name": "setSubjectFeePercent",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_newUsdtPrice",
                "type": "uint256"
            }
        ],
        "name": "setUsdtPrice",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            },
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "sharesBalance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "subjectFeePercent",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "usdtPriceInWei",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "userPostCount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]

export function useEthereumManager() {
    const { login: privyLogin, logout: privyLogout, authenticated, user, ready } = usePrivy();
    const { wallets } = useWallets();
  
    const FANTOM_TESTNET_CHAIN_ID = '0x79a';

  const ensureCorrectNetwork = async (provider) => {
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: FANTOM_TESTNET_CHAIN_ID }],
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [{
              chainId: FANTOM_TESTNET_CHAIN_ID,
              chainName: 'Fantom Testnet',
              nativeCurrency: {
                name: 'Ethereum',
                symbol: 'ETH',
                decimals: 18
              },
              rpcUrls: ['https://rpc.minato.soneium.org'],
              blockExplorerUrls: ['https://explorer-testnet.soneium.org/']
            }],
          });
        } catch (addError) {
          console.error('Failed to add Fantom Testnet:', addError);
        }
      } else {
        console.error('Failed to switch to Fantom Testnet:', switchError);
      }
    }
  };

  const getWalletAndSigner = async () => {
    if (!authenticated || wallets.length === 0) return { wallet: null, signer: null };
    const wallet = wallets[0];
    const provider = await wallet.getEthereumProvider();
    
    await ensureCorrectNetwork(provider);
  
    const web3Provider = new ethers.providers.Web3Provider(provider);
    const signer = web3Provider.getSigner();
    const address = await signer.getAddress();
    return { wallet, signer, provider: web3Provider, address };
  };

  const getBalance = async (address) => {
    const { provider } = await getWalletAndSigner();
    if (!provider) throw new Error("Provider not available");

    try {
      const balance = await provider.getBalance(address);
      return balance;
    } catch (error) {
      console.error("Error getting balance:", error);
      throw error;
    }
  };

  const createPost = async (content) => {
    const { wallet, signer } = await getWalletAndSigner();
    if (!wallet || !signer) throw new Error("Wallet not available");

    const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, signer);
    
    try {
      const feeInWei = await contract.calculateFeeInWei(1);
      console.log('Creating post with fee:', ethers.utils.formatEther(feeInWei), 'ETH');
      
      const estimatedGas = await contract.estimateGas.createPost(content, { value: feeInWei });
      const tx = await contract.createPost(content, { 
        value: feeInWei,
        gasLimit: estimatedGas.mul(120).div(100)
      });
      
      console.log('Transaction sent:', tx.hash);
      const receipt = await tx.wait();
      console.log('Transaction confirmed:', receipt.transactionHash);
      return receipt.transactionHash;
    } catch (error) {
      console.error("Error creating post:", error);
      throw error;
    }
  };

  const addComment = async (uniquePostId, content, parentCommentId = null) => {
    if (wallets.length === 0) throw new Error("No wallets available");
    
    const wallet = wallets[0];
    const provider = await wallet.getEthereumProvider();
    
    try {
      console.log('Starting addComment function');
      const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, new ethers.providers.Web3Provider(provider).getSigner());
      const feeInWei = await contract.calculateFeeInWei(1);
  
      console.log('Debug info:', {
        uniquePostId,
        content,
        parentCommentId,
        feeInWei: ethers.utils.formatEther(feeInWei),
        userAddress: wallet.address,
      });
  
      const safeParentCommentId = parentCommentId || ethers.constants.HashZero;
  
      const data = encodeFunctionData({
        abi: BANGER_ABI,
        functionName: 'addComment',
        args: [uniquePostId, content, safeParentCommentId]
      });
  
      const transactionRequest = {
        to: BANGER_ADDRESS,
        data: data,
        value: ethers.utils.hexValue(feeInWei),
        from: wallet.address,
      };
  
      console.log('Preparing to send transaction:', transactionRequest);
  
      const txHash = await provider.request({
        method: 'eth_sendTransaction',
        params: [transactionRequest],
      });
  
      console.log('Transaction sent. Hash:', txHash);
  
      const receipt = await waitForTransaction(provider, txHash);
      console.log('Transaction confirmed:', receipt);
  
      const commentAddedEvent = receipt.logs.find(log => 
        log.address.toLowerCase() === BANGER_ADDRESS.toLowerCase()
      );
  
      if (!commentAddedEvent) {
        throw new Error("CommentAdded event not found in transaction logs");
      }
  
      const decodedEvent = decodeEventLog({
        abi: BANGER_ABI,
        data: commentAddedEvent.data,
        topics: commentAddedEvent.topics,
      });
  
      console.log('Decoded CommentAdded event:', decodedEvent);
  
      if (!decodedEvent.args) {
        throw new Error('Decoded event args not found');
      }
  
      const uniqueCommentId = decodedEvent.args.uniqueCommentId;
      const commentId = decodedEvent.args.commentId.toString();
      const amountPaid = decodedEvent.args.amountPaid;
      const isBoosted = decodedEvent.args.isBoosted;
  
      console.log('Extracted from event:', { uniqueCommentId, commentId, amountPaid, isBoosted });
  
      if (!uniqueCommentId) {
        throw new Error('UniqueCommentId not found in decoded event');
      }
  
      return { txHash, commentId, uniqueCommentId, amountPaid, isBoosted };
    } catch (error) {
      console.error("Error adding comment:", error);
      throw error;
    }
  };

  const getCommentReplies = async (uniqueCommentId) => {
    const { wallet, signer } = await getWalletAndSigner();
    if (!wallet || !signer) throw new Error("Wallet not available");
  
    const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, signer);
    
    try {
      const replyIds = await contract.getCommentReplies(uniqueCommentId);
      return replyIds.map(id => id.toString());
    } catch (error) {
      console.error("Error getting comment replies:", error);
      return [];
    }
  };

  const addBoostedComment = async (uniquePostId, content, amount) => {
    if (wallets.length === 0) throw new Error("No wallets available");
    
    const wallet = wallets[0];
    const provider = await wallet.getEthereumProvider();
    
    try {
      console.log('Starting addBoostedComment function');
      const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, new ethers.providers.Web3Provider(provider).getSigner());
      const minFeeInWei = await contract.calculateFeeInWei(10);
      const feeInWei = ethers.utils.parseEther(amount.toString());
      
      if (feeInWei.lt(minFeeInWei)) {
        throw new Error("Boosted comment amount is less than the minimum required");
      }
  
      console.log('Debug info:', {
        uniquePostId,
        content,
        feeInWei: ethers.utils.formatEther(feeInWei),
        userAddress: wallet.address,
      });
  
      const data = encodeFunctionData({
        abi: BANGER_ABI,
        functionName: 'addBoostedComment',
        args: [uniquePostId, content]
      });
  
      const transactionRequest = {
        to: BANGER_ADDRESS,
        data: data,
        value: ethers.utils.hexValue(feeInWei),
        from: wallet.address,
      };
  
      console.log('Preparing to send transaction:', transactionRequest);
  
      const txHash = await provider.request({
        method: 'eth_sendTransaction',
        params: [transactionRequest],
      });
  
      console.log('Transaction sent. Hash:', txHash);
  
      const receipt = await waitForTransaction(provider, txHash);
      console.log('Transaction confirmed:', receipt);
  
      return txHash;
    } catch (error) {
      console.error("Error adding boosted comment:", error);
      if (error.code === 'INSUFFICIENT_FUNDS') {
        throw new Error("Insufficient funds to add boosted comment");
      } else if (error.code === 'UNPREDICTABLE_GAS_LIMIT') {
        throw new Error("Unable to estimate gas. The transaction may fail or the contract conditions are not met.");
      } else {
        throw error;
      }
    }
  };

  const waitForTransaction = (provider, txHash) => {
    return new Promise((resolve, reject) => {
      const checkReceipt = async () => {
        try {
          const receipt = await provider.request({
            method: 'eth_getTransactionReceipt',
            params: [txHash],
          });
          if (receipt) {
            resolve(receipt);
          } else {
            setTimeout(checkReceipt, 1000);
          }
        } catch (error) {
          reject(error);
        }
      };
      checkReceipt();
    });
  };

  const quoteTweet = async (originalUniquePostId, content) => {
    if (wallets.length === 0) throw new Error("No wallets available");
    
    const wallet = wallets[0];
    const provider = await wallet.getEthereumProvider();
    
    try {
      console.log('Starting quoteTweet function');
      const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, new ethers.providers.Web3Provider(provider).getSigner());
      const feeInWei = await contract.calculateFeeInWei(1);
  
      console.log('Debug info:', {
        originalUniquePostId,
        content,
        feeInWei: ethers.utils.formatEther(feeInWei),
        userAddress: wallet.address,
      });
  
      const data = encodeFunctionData({
        abi: BANGER_ABI,
        functionName: 'quoteTweet',
        args: [originalUniquePostId, content]
      });
  
      const transactionRequest = {
        to: BANGER_ADDRESS,
        data: data,
        value: ethers.utils.hexValue(feeInWei),
        from: wallet.address,
      };
  
      console.log('Preparing to send transaction:', transactionRequest);
  
      const txHash = await provider.request({
        method: 'eth_sendTransaction',
        params: [transactionRequest],
      });
  
      console.log('Transaction sent. Hash:', txHash);
  
      const receipt = await waitForTransaction(provider, txHash);
      console.log('Transaction confirmed:', receipt);
  
      return txHash;
    } catch (error) {
      console.error("Error quote tweeting:", error);
      if (error.code === 'INSUFFICIENT_FUNDS') {
        throw new Error("Insufficient funds to quote tweet");
      } else if (error.code === 'UNPREDICTABLE_GAS_LIMIT') {
        throw new Error("Unable to estimate gas. The transaction may fail or the contract conditions are not met.");
      } else {
        throw error;
      }
    }
  };

  const getComments = async (uniqueId) => {
    const { wallet, signer } = await getWalletAndSigner();
    if (!wallet || !signer) throw new Error("Wallet not available");

    const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, signer);
    
    try {
      return await contract.getComments(uniqueId);
    } catch (error) {
      console.error("Error getting comments:", error);
      return [];
    }
  };

  const getUserPosts = async (userId) => {
    const { wallet, signer, address } = await getWalletAndSigner();
    if (!wallet || !signer) throw new Error("Wallet not available");
  
    const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, signer);
    
    try {
      console.log('Fetching posts for address:', address);
      const posts = await contract.getUserPosts(address);
      console.log('Raw posts data:', posts);
      return posts.map(post => ({
        id: post.id.toString(),
        uniqueId: post.uniqueId,
        content: post.content,
        author: post.author,
        timestamp: post.timestamp.toNumber(),
        postType: post.postType
      }));
    } catch (error) {
      console.error("Error getting user posts:", error);
      return [];
    }
  };

  const getUserPostCount = async (address) => {
    const { wallet, signer } = await getWalletAndSigner();
    if (!wallet || !signer) throw new Error("Wallet not available");

    const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, signer);
    
    try {
      const count = await contract.getUserPostCount(address);
      return count.toNumber();
    } catch (error) {
      console.error("Error getting user post count:", error);
      return 0;
    }
  };

  const getUsdtPrice = async () => {
    const { wallet, signer } = await getWalletAndSigner();
    if (!wallet || !signer) throw new Error("Wallet not available");

    const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, signer);
    
    try {
      const price = await contract.getUsdtPrice();
      return ethers.utils.formatUnits(price, 18);
    } catch (error) {
      console.error("Error getting USDT price:", error);
      throw error;
    }
  };

  const getEthPrice = async () => {
    const { wallet, signer } = await getWalletAndSigner();
    if (!wallet || !signer) throw new Error("Wallet not available");

    const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, signer);
    
    try {
      const price = await contract.getEthPrice();
      return ethers.utils.formatUnits(price, 18);
    } catch (error) {
      console.error("Error getting ETH price:", error);
      throw error;
    }
  };


  const getDebugPriceAndFee = async () => {
    const { wallet, signer } = await getWalletAndSigner();
    if (!wallet || !signer) throw new Error("Wallet not available");

    const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, signer);
    
    try {
      const [usdtPrice, ftmPrice, feeInWei] = await contract.debugPriceAndFee();
      return {
        usdtPrice: ethers.utils.formatUnits(usdtPrice, 18),
        ftmPrice: ethers.utils.formatUnits(ftmPrice, 18),
        feeInWei: ethers.utils.formatEther(feeInWei)
      };
    } catch (error) {
      console.error("Error getting debug price and fee:", error);
      throw error;
    }
  };

  const getHighestPaidCommentAmount = async (uniquePostId) => {
    const { wallet, signer } = await getWalletAndSigner();
    if (!wallet || !signer) throw new Error("Wallet not available");
  
    const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, signer);
    
    try {
      const amount = await contract.getHighestPaidCommentAmount(uniquePostId);
      return ethers.utils.formatEther(amount);
    } catch (error) {
      console.error("Error getting highest paid comment amount:", error);
      throw error;
    }
  };
  
  const getPostByUniqueId = async (uniqueId) => {
    const { wallet, signer } = await getWalletAndSigner();
    if (!wallet || !signer) throw new Error("Wallet not available");
  
    const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, signer);
    
    try {
      const post = await contract.getPostByUniqueId(uniqueId);
      return {
        id: post.id.toString(),
        uniqueId: post.uniqueId,
        content: post.content,
        author: post.author,
        timestamp: post.timestamp.toNumber(),
        postType: post.postType
      };
    } catch (error) {
      console.error("Error getting post by unique ID:", error);
      throw error;
    }
  };
  
  const getCommentByUniqueId = async (uniqueId) => {
    const { wallet, signer } = await getWalletAndSigner();
    if (!wallet || !signer) throw new Error("Wallet not available");
  
    const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, signer);
    
    try {
      const comment = await contract.getCommentByUniqueId(uniqueId);
      return {
        id: comment.id.toString(),
        uniqueId: comment.uniqueId,
        commenter: comment.commenter,
        content: comment.content,
        timestamp: comment.timestamp.toNumber(),
        amountPaid: ethers.utils.formatEther(comment.amountPaid),
        isBoosted: comment.isBoosted,
        parentPostId: comment.parentPostId,
        parentCommentId: comment.parentCommentId
      };
    } catch (error) {
      console.error("Error getting comment by unique ID:", error);
      throw error;
    }
  };

  const getBoostedCommentAmounts = async (uniquePostId) => {
    const { wallet, signer } = await getWalletAndSigner();
    if (!wallet || !signer) throw new Error("Wallet not available");
  
    const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, signer);
    
    try {
      const amounts = await contract.getBoostedCommentAmounts(uniquePostId);
      return amounts.map(amount => ethers.utils.formatEther(amount));
    } catch (error) {
      console.error("Error getting boosted comment amounts:", error);
      throw error;
    }
  };
  
  const listenToEvents = (contract) => {
    contract.on("PostCreated", (author, postId, uniqueId, content, postType) => {
      console.log("Post created:", { 
        author, 
        postId: postId.toNumber(), 
        uniqueId, 
        content, 
        postType 
      });
    });
  
    contract.on("CommentAdded", (commenter, postOwner, postId, commentId, uniqueCommentId, content, amountPaid, isBoosted, parentCommentId) => {
      console.log("Comment added:", { 
        commenter, 
        postOwner, 
        postId: postId.toNumber(), 
        commentId: commentId.toNumber(), 
        uniqueCommentId,
        content,
        amountPaid: ethers.utils.formatEther(amountPaid),
        isBoosted,
        parentCommentId
      });
    });
  
    contract.on("QuoteTweetCreated", (author, postId, uniqueId, originalPoster, originalPostId, content) => {
      console.log("Quote tweet created:", { 
        author, 
        postId: postId.toNumber(), 
        uniqueId,
        originalPoster, 
        originalPostId: originalPostId.toNumber(), 
        content 
      });
    });
  
    contract.on("HighestPaidCommentUpdated", (uniquePostId, newHighestAmount) => {
      console.log("Highest paid comment updated:", {
        uniquePostId,
        newHighestAmount: ethers.utils.formatEther(newHighestAmount)
      });
    });
  
    contract.on("BoostedCommentAdded", (uniquePostId, amount) => {
      console.log("Boosted comment added:", {
        uniquePostId,
        amount: ethers.utils.formatEther(amount)
      });
    });
  };
  
  const initializeEventListeners = async () => {
    const { signer } = await getWalletAndSigner();
    if (signer) {
      const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, signer);
      listenToEvents(contract);
    }
  };
  const buyShares = async (postUniqueId, amount) => {
    if (wallets.length === 0) throw new Error("No wallets available");
    
    const wallet = wallets[0];
    const provider = await wallet.getEthereumProvider();
    
    try {
      const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, new ethers.providers.Web3Provider(provider).getSigner());
  
      // Get the buy price
      const price = await contract.getBuyPrice(postUniqueId, amount);
      console.log('Buy price:', ethers.utils.formatEther(price), 'ETH');
  
      // Calculate fees
      const protocolFee = price.mul(ethers.utils.parseEther('0.05')).div(ethers.utils.parseEther('1'));
      const subjectFee = price.mul(ethers.utils.parseEther('0.05')).div(ethers.utils.parseEther('1'));
      const totalCost = price.add(protocolFee).add(subjectFee);
  
      console.log('Total cost:', ethers.utils.formatEther(totalCost), 'ETH');
  
      // Check user's balance
      const balance = await provider.request({
        method: 'eth_getBalance',
        params: [wallet.address, 'latest'],
      });
  
      console.log('User balance:', ethers.utils.formatEther(balance), 'ETH');
  
      if (ethers.BigNumber.from(balance).lt(totalCost)) {
        throw new Error("Insufficient balance to complete the transaction");
      }
  
      // Estimate gas
      let gasEstimate;
      try {
        gasEstimate = await contract.estimateGas.buyShares(postUniqueId, amount, { value: totalCost });
      } catch (error) {
        console.error('Gas estimation failed:', error);
        // If gas estimation fails, use a default gas limit
        gasEstimate = ethers.BigNumber.from('300000'); // Adjust this value as needed
      }
      console.log('Estimated gas:', gasEstimate.toString());
  
      // Add a buffer to the gas estimate
      const gasLimit = gasEstimate.mul(120).div(100); // 20% buffer
  
      const transactionRequest = {
        to: BANGER_ADDRESS,
        data: contract.interface.encodeFunctionData('buyShares', [postUniqueId, amount]),
        value: ethers.utils.hexValue(totalCost),
        chainId: CHAIN_ID,
        from: wallet.address,
        gasLimit: ethers.utils.hexValue(gasLimit),
      };
  
      console.log('Transaction request:', transactionRequest);
  
      const txHash = await provider.request({
        method: 'eth_sendTransaction',
        params: [transactionRequest],
      });
  
      console.log('Transaction sent. Hash:', txHash);
  
      const receipt = await waitForTransaction(provider, txHash);
      console.log('Transaction confirmed:', receipt);
  
      return txHash;
    } catch (error) {
      console.error("Error buying shares:", error);
      throw error;
    }
  };

  const sellShares = async (postUniqueId, amount) => {
    if (wallets.length === 0) throw new Error("No wallets available");
    
    const wallet = wallets[0];
    const provider = await wallet.getEthereumProvider();
    
    try {
      const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, new ethers.providers.Web3Provider(provider).getSigner());
  
      // Get the sell price
      const price = await contract.getSellPrice(postUniqueId, amount);
      console.log('Sell price:', ethers.utils.formatEther(price), 'ETH');
  
      // Calculate fees
      const protocolFee = price.mul(ethers.utils.parseEther('0.05')).div(ethers.utils.parseEther('1'));
      const subjectFee = price.mul(ethers.utils.parseEther('0.05')).div(ethers.utils.parseEther('1'));
      const totalProceeds = price.sub(protocolFee).sub(subjectFee);
  
      console.log('Total proceeds:', ethers.utils.formatEther(totalProceeds), 'ETH');
  
      // Check user's balance
      const balance = await provider.request({
        method: 'eth_getBalance',
        params: [wallet.address, 'latest'],
      });
  
      console.log('User balance:', ethers.utils.formatEther(balance), 'ETH');
  
      // Estimate gas
      let gasEstimate;
      try {
        gasEstimate = await contract.estimateGas.sellShares(postUniqueId, amount);
      } catch (error) {
        console.error('Gas estimation failed:', error);
        // If gas estimation fails, use a default gas limit
        gasEstimate = ethers.BigNumber.from('300000'); // Adjust this value as needed
      }
      console.log('Estimated gas:', gasEstimate.toString());
  
      // Add a buffer to the gas estimate
      const gasLimit = gasEstimate.mul(120).div(100); // 20% buffer
  
      const transactionRequest = {
        to: BANGER_ADDRESS,
        data: contract.interface.encodeFunctionData('sellShares', [postUniqueId, amount]),
        chainId: CHAIN_ID,
        from: wallet.address,
        gasLimit: ethers.utils.hexValue(gasLimit),
      };
  
      console.log('Transaction request:', transactionRequest);
  
      const txHash = await provider.request({
        method: 'eth_sendTransaction',
        params: [transactionRequest],
      });
  
      console.log('Transaction sent. Hash:', txHash);
  
      const receipt = await waitForTransaction(provider, txHash);
      console.log('Transaction confirmed:', receipt);
  
      return txHash;
    } catch (error) {
      console.error("Error selling shares:", error);
      throw error;
    }
  };

  const getBuyPrice = async (postUniqueId, amount) => {
    try {
      const { wallet, signer } = await getWalletAndSigner();
      if (!wallet || !signer) throw new Error("Wallet not available");
  
      const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, signer);
      
      console.log('Attempting to get buy price for:', postUniqueId, amount);
      const price = await contract.getBuyPrice(postUniqueId, amount);
      console.log('Received price:', price.toString());
      return ethers.utils.formatEther(price);
    } catch (error) {
      console.error("Error getting buy price:", error);
      console.error("Contract address:", BANGER_ADDRESS);
      console.error("Post Unique ID:", postUniqueId);
      console.error("Amount:", amount);
      throw error;
    }
  };

  const getSellPrice = async (postUniqueId, amount) => {
    const { wallet, signer } = await getWalletAndSigner();
    if (!wallet || !signer) throw new Error("Wallet not available");

    const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, signer);
    
    try {
      const price = await contract.getSellPrice(postUniqueId, amount);
      return ethers.utils.formatEther(price);
    } catch (error) {
      console.error("Error getting sell price:", error);
      throw error;
    }
  };

  const getPrice = async (supply, amount) => {
    const { wallet, signer } = await getWalletAndSigner();
    if (!wallet || !signer) throw new Error("Wallet not available");

    const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, signer);
    
    try {
      const price = await contract.getPrice(supply, amount);
      return ethers.utils.formatEther(price);
    } catch (error) {
      console.error("Error getting price:", error);
      throw error;
    }
  };

  const getPostSupply = async (postUniqueId) => {
    const { wallet, signer } = await getWalletAndSigner();
    if (!wallet || !signer) throw new Error("Wallet not available");

    const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, signer);
    
    try {
      const supply = await contract.getPostSupply(postUniqueId);
      return supply.toNumber();
    } catch (error) {
      console.error("Error getting post supply:", error);
      throw error;
    }
  };

  const getSharesBalance = async (postUniqueId, userAddress) => {
    const { wallet, signer } = await getWalletAndSigner();
    if (!wallet || !signer) throw new Error("Wallet not available");

    const contract = new ethers.Contract(BANGER_ADDRESS, BANGER_ABI, signer);
    
    try {
      const balance = await contract.getSharesBalance(postUniqueId, userAddress);
      return balance.toNumber();
    } catch (error) {
      console.error("Error getting shares balance:", error);
      throw error;
    }
  };

  const getBatchBuyPrices = async (postUniqueIds) => {
    try {
      const response = await fetch('/api/batch-prices', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ postIds: postUniqueIds }),
      });
      if (!response.ok) {
        throw new Error('Failed to fetch batch prices');
      }
      return await response.json();
    } catch (error) {
      console.error("Error getting batch buy prices:", error);
      throw error;
    }
  };
  
  return {
    createPost,
    addComment,
    addBoostedComment,
    quoteTweet,
    getComments,
    getUserPosts,
    getUserPostCount,
    getUsdtPrice,
    getEthPrice,
    getDebugPriceAndFee,
    getHighestPaidCommentAmount,
    getPostByUniqueId,
    getCommentByUniqueId,
    getBoostedCommentAmounts,
    initializeEventListeners,
    authenticated,
    ready,
    login: privyLogin,
    logout: privyLogout,
    getBalance,
    getCommentReplies,
    buyShares,
    sellShares,
    getBuyPrice,
    getSellPrice,
    getPrice,
    getPostSupply,
    getSharesBalance,
    getBatchBuyPrices,
  };
}

export default useEthereumManager;